<template>

  <v-app-bar
    app
    dense
    fixed
    flat
    color="black" 
    dark
   
  >
    

  





  <v-dialog
        v-model="dialogmenu"
        width="500"
      >
       
  
        <v-card class="text-h5 lighten-2">
          <v-card-title  >
           Soluciones:
          </v-card-title>
<v-divider></v-divider>
       <br/>   
          <v-card-text >

         
           <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">

    
          <v-btn
         
            dark
            v-bind="attrs"
            v-on="on"
          >
           Cierres de Cristal </v-btn>
        </template>
      <v-list>
                <v-list-item-group color="primary">
                  <v-list-item 
                    @click="dialogmenu = false"
                  v-for="(item, index) in cierresplegables" :key="index">
                    <router-link
                    
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        
                      name: item.name,
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

      </v-menu>





       <br> <br> 
<v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
         
            dark
            v-bind="attrs"
            v-on="on"
          >
          Persianas y Cortinas
          </v-btn>
        </template>
      <v-list>
                <v-list-item-group color="primary">
                  
                  <v-list-item
                  
                     @click="dialogmenu = false"
                   v-for="(item, index) in CortinasRoller" :key="index">
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        name: 'Servicio',
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

      </v-menu>

 <br> <br> 
<v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
         
            dark
            v-bind="attrs"
            v-on="on"
          >
          Casa Inteligente
          </v-btn>
        </template>
      <v-list>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, index) in  casainteligentes" :key="index">
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        name: 'Servicio',
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

      </v-menu>


 <br> <br> 
<v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
      
            dark
            v-bind="attrs"
            v-on="on"
             to="/deco/"
          >
           Decoración de Interior
          </v-btn>
        </template>
      <v-list>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, index) in x" :key="index">
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        name: 'Rain',
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

      </v-menu>
 <br> <br> 
<v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
         
            dark
            v-bind="attrs"
            v-on="on"
             to="/Nuestrosclientes/"
          >
         Testimonios de Clientes
          </v-btn>
        </template>
      <v-list>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, index) in x" :key="index">
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        name: 'nuestrosClientes',
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

      </v-menu>

       <br> <br> 
<v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
         
            dark
            v-bind="attrs"
            v-on="on"
             @click="dialogmenu = false"
            to="/blog/"
          >
         Blog ValoraDesign
          
          </v-btn>
        </template>
     

      </v-menu>
  
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="dialogmenu = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>




    <v-container>
    <v-icon     class="hidden-sm-and-up" @click.stop="dialogmenu = !dialogmenu">
              mdi-menu
            </v-icon>
        <v-navigation-drawer width="450" dark color="black" left v-model="drawer" app>
          <v-list-tile-action>
            <v-spacer></v-spacer>
            <v-icon @click.stop="drawer = !drawer">
              mdi-menu
            </v-icon>
 <br> <br> <br>


          </v-list-tile-action>

        </v-navigation-drawer>
    

      

      
          <div class="d-flex align-center"    >
          
          </div>

          <v-toolbar-title></v-toolbar-title>
          <v-tabs
            class="hidden-sm-and-down"
    
            hide-slider
            optional
          >
            <v-tab to="/">Inicio</v-tab>

            <v-menu
              open-on-hover
              offset-y
              bottom
              origin="center center"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">Cierre de Cristal </v-tab>
              </template>

              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, index) in cierresplegables" :key="index">
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        name: item.name,
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>



            <v-menu
              open-on-hover
              offset-y
              bottom
              origin="center center"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">Persianas y Cortinas</v-tab>
              </template>

              <v-list>
                <v-list-item-group color="primary">
            <v-list-item
                  
                     @click="dialogmenu = false"
                   v-for="(item, index) in CortinasRoller" :key="index">
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{
                        name: 'Servicio',
                        params: { id: item.value },
                        hash: item.value,
                      }"
                    >
                      <v-list-item-title> {{ item.text }}</v-list-item-title>
                    </router-link>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

                  <v-tab to="/deco">Decoración de Interior </v-tab>

            
             <v-tab to="/Nuestrosclientes/">Testimonios Clientes</v-tab>

      <v-tab to="/blog/">Blog</v-tab>
      <v-tab to="/trabajos/">Trabaja con Nosotros</v-tab>
      </v-tabs>
   

    </v-container>
  </v-app-bar>

</template>


<script>

export default {
  name: "Navbar",
  components: { },
  props: [],

  data() {
    return {
dialogmenu:false,
banquinas:[
  
 { text: "Banquinas de Aluminio ", value: "#acerca"},

      { text: "Pinzas de Aluminio ", value: "#acerca" },

  

    ],

    MostarRegistro:false,

alias: null,
      feedback: null,
refreshing: false,
      registration: null,
      snackBtnText: '',
      snackWithBtnText: '',
      snackWithButtons: false,
      collapseOnScroll: true,
    snackbar: true,
    text: "My timeout is set to 2000.",
    timeout: 10000,
    fab: false,
    hidden: false,

    CortinasRoller: [
  
 { text: "Roller Screen", value: "#SCREEN"},

      { text: "Roller Duo", value: "#DUO" },
      { text: "Roller BlackOut", value: "#BLACKOUT" },
      { text: "Roller Luxury", value: "#LUXURY" },
      { text: "Cortina Vertical", value: "#VERTICALES" },
     { text: "Cortina Tradicional", value: "#TRADICIONAL" },
        { text: "Según tu Ambiente", value: "#Ambiente" }

    ],

 casainteligentes: [
  
 { text: "Chapa Inteligente", value: "#Chapa"},
  { text: "Timbre Inteligente", value: "#Timbre" },
      { text: "Domótica por voz", value: "#Domotica" },


    ],


    productos: [],
    sectores: [
      { text: "Cliente 1", value: "#agro" },
      { text: "Cliente 2", value: "#retail" },
      { text: "Cliente 3", value: "#produccion" },
      { text: "Cliente 4", value: "#salud" },
    ],
    cierresplegables: [
      { text: "Cierre de Cristal Plegable para Balcón", value: "#vision", name:"cierredecristal" },
      { text: "Cierre de Cristal Plegable para Terraza  ", value: "#vision_Piso_Cielo" , name:"cierreplegableterraza" },
      { text: "Cierre Plegable Termo Panel", value: "#vision_termo", name:"cierre_plegable_termo" },
 
    ],
    soporte: [],
    contacto: [],
user:null,
    

      dialogm1: "",
      dialog: false,

      drawer: false,
      links: [
        { icon: "dashboard", text: "Chef", route: "/" },
        { icon: "folder", text: "Exótico", route: "/projects" },
        { icon: "person", text: "Lo más comprado", route: "/team" }
      ]
    };
  },
  methods: {
    web() {
      this.$router.push({ name: "web" });
      this.dialog = false;
    },

    ecommerce() {
      this.$router.push({ path: "/ecommerce" });
      this.dialog = false;
    },

    market() {
      this.$router.push({ path: "/market" });
      this.dialog = false;
    },
    apps() {
      this.$router.push({ path: "/apps" });
      this.dialog = false;
    },
    foto() {
      this.$router.push({ path: "/foto" });
      this.dialog = false;
    },
    gestion() {
      this.$router.push({ path: "/social" });
      this.dialog = false;
    },
    inicio() {
      this.$router.push({ path: "/" });
      this.dialog = false;
    }
  }
};
</script>
