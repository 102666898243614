<template>
  <v-container >



    <v-row>
      <v-col
        v-for="n in items"
        :key="n"
        class="d-flex child-flex"
        cols="4"
      >
        <v-img
          :src="n.src"
          :lazy-src="n.src"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>


import db from "@/firebase/init.js";
import firebase from "firebase";
export default {
  name: "Ecommerce",
  components: {
    },
  props: [],

  data() {
    return {
       items: [
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%201.png?alt=media&token=7e9a316b-002d-4727-a225-5292007e2725',
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%2010.png?alt=media&token=b54854c1-677e-4872-a723-5657d705f2a1',
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%2011.png?alt=media&token=768a4af0-6ab2-4d42-aca7-780c403582ea',
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%203.png?alt=media&token=bfa2e651-36d5-417b-b416-b6d9f94f997d',
      },

        {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%202.png?alt=media&token=63993621-2251-431e-bb55-6057f960821b',
      },
 {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%204.png?alt=media&token=951a3151-ebb2-4982-bc6b-20d57204c188',
      }, {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%205.png?alt=media&token=39b48421-2557-4f95-acd5-40c6c31f1e31',
      }, {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%206.png?alt=media&token=fa37af36-7162-43ab-88ee-f0b9fbbb8339',
      }, {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%207.png?alt=media&token=71fb1ef0-8e42-410c-a50e-1db0a35ef991',
      }, {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%208.png?alt=media&token=7e1c630b-3ed7-4df3-9faa-6a98b4422c10',
      },

 {
        src: 'https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/deco%2FImagen%209.png?alt=media&token=453fb439-581b-4b38-b6d8-a3b322d2758e',
      },

    ],
    selected: [],
    };
  },
  created() {
    var docRef = db.collection("web").doc("Servicios Sap");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().AF1;
          this.foto2 = doc.data().AF0;
          this.titulo = doc.data().BN1;
          this.mensaje = doc.data().CM0;
          this.descripcion = doc.data().DD1;
          this.funcion = doc.data().EF0;
          this.funciones = doc.data().EF1;
          this.areas = doc.data().FB1;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "230px";
        case "sm":
          return "290px";
        case "md":
          return "363px";
        case "lg":
          return "435px";
        case "xl":
          return "600px";
      }
    },

    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "290px";
        case "sm":
          return "320px";
        case "md":
          return "4200px";
        case "lg":
          return "610px";
        case "xl":
          return "650px";
      }
    },
  },
};
</script>
