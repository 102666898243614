<template>
  <v-container fluid class="black" align="center"  > 
   <v-app class="black">

    <v-data-table
      :headers="Headers"
      :items="p"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="name"
      show-expand
     
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Oportunidades de Trabajos</v-toolbar-title>
          <v-spacer></v-spacer>
       
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.Descripcion }}
        </td>
      </template>
    </v-data-table>
  </v-app>
  </v-container>
</template>

<script>


import axios from "axios"

export default {
  name: "trabajos",
  components: {},
  props: [],

  data() {
    return {
      p: [],
 expanded: [],
     
      Headers: [
        {
          text: 'Nombre del Cargo ',
          align: 'start',
          sortable: false,
          value: 'Cargo',
          class: 'primary--text subtitle'
        },
        { text: 'Rango Salario', value: 'Salario',  class: 'primary--text subtitle'},
        { text: 'Fecha de Ingreso', value: 'Ingreso', class: 'primary--text subtitle' },
        { text: 'Competencias', value: 'Competencias', class: 'primary--text subtitle' },
        { text: 'Certificaciones ', value: 'Certificaciones', class: 'primary--text subtitle' },
        { text: 'Enviar Correo', value: 'Correo', class: 'primary--text subtitle' },
        { text: 'Más Info', value: 'data-table-expand', class: 'primary--text subtitle' },
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
    };
  },
  mounted() {
    scrollTo(this.$route.params.id, 500000);
  },
  created() {

let _self = this;
axios.get("https://sheetdb.io/api/v1/zkz858ssv5au8?sheet=EMPLEOS").then((response) => {
    _self.p = response.data;
    });


     
       
  },
  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },

    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },

  watch: {
    $route() {
      this.id = this.$route.params.id;
    },

    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  computed: {
   

    
  },
};
</script>
