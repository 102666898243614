<template>

<v-app id="landing-page">


<Navbar/>
 <br/>  <br/>         
            <router-view> </router-view>
     <v-footer
        class="pb-7 "       
    inset width="auto"
                color="black " app>
          <v-row align="center" justify="center" blue lighten-5>
           

 <v-btn
              class="ma-4   py-5  px-1"     
              color="#6c6c6c"
              dark
       x-small
              href="https://valoradesign.cl/"
            >
              <v-icon dark>
               mdi-home
              </v-icon>
            
            </v-btn>


            <v-btn
              class="ma-2   py-5  px-1"     
              color="#6c6c6c"
              dark
             x-small
              elevation="2"
              href="https://calendly.com/oriettamarambio/reunion-de-15-minutos"
            >
              <v-icon dark>
                mdi-calendar-clock
              </v-icon>
             Agendar<br/>conversación
            </v-btn>
           
            <v-btn
              class="ma-2   py-5  px-1"     
              color="#6c6c6c"
              dark
         x-small
              href="tel://+56977480204"
            >
              <v-icon dark>
                mdi-cellphone-android
              </v-icon>
             +569 77480204
            </v-btn>
            <v-fab-transition>
              <v-btn

                href="https://api.whatsapp.com/send?phone=+56977480204&text=Hola,%20quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n%20de%20ValoraDesign"
                v-show="!hidden"
                color="#00f500"
                dark
                absolute
                top
 right
                fab
              >
                <v-icon x-large>mdi-whatsapp</v-icon>
                <v-snackbar
               x-small
                  light
                  v-model="snackbar"
                  timeout="7000"
                 right
                  rounded="pill"
                >
                 
     <v-btn

       small
            text
            color="black"
            v-bind="attrs"
           href="https://api.whatsapp.com/send?phone=+56977480204&text=Hola,%20quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n%20de%20ValoraDesign"
          >
 <v-list-item-avatar>
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/lists/4.jpg"
                    ></v-img>
                  </v-list-item-avatar>

         Hola, Bienvenido <br/>a ValoraDesign
          </v-btn>

                
                </v-snackbar>
              </v-btn>
            </v-fab-transition>
          </v-row>
        </v-footer>

<v-snackbar
        v-model="snackWithButtons"
        bottom
        left
        timeout="-1"
      >
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            {{ snackBtnText }}
          </v-btn>
          <v-btn
            icon
            class="ml-4"
            @click="snackWithButtons = false"
          >
          
          </v-btn>
        </template>
      </v-snackbar>


  </v-app>
</template>

<script>

import Navbar from "@/components/Navbar.vue";
import db from "@/firebase/init.js";
import firebase from "firebase";

export default {
  name: "App",

  components: {Navbar, },

  data: () => ({

  dialog: false,
    tab: 0,
    tabs: [
        {name:"Login", icon:"mdi-account"},
        {name:"Registro", icon:"mdi-account-outline"},
           {name:"Google", icon:"mdi-google"}
    ],
    valid: true,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      v => !!v || "Required",
      v => /.+@.+\..+/.test(v) || "E-mail debe ser valido"
    ],
    emailRules: [
      v => !!v || "Required",
      v => /.+@.+\..+/.test(v) || "E-mail debe ser valido"
    ],

    show1: false,
    rules: {
      required: value => !!value || "Requerido.",
      min: v => (v && v.length >= 8) || "Min 8 characters"
    },



    MostarRegistro:false,

alias: null,
      feedback: null,
refreshing: false,
      registration: null,
      snackBtnText: '',
      snackWithBtnText: '',
      snackWithButtons: false,
      collapseOnScroll: true,
    snackbar: true,
    text: "My timeout is set to 2000.",
    timeout: 10000,
    fab: false,
    hidden: false,

    CortinasRoller: [
  
 { text: "AMBIENTES", value: "#ANA" },

      { text: "ROLLERS", value: "#ABAP" },
      { text: "PERSINAS", value: "#SM" },
      { text: "CORTINAS", value: "#CRM" },
      { text: "EXTERIOR", value: "#S4" },
  { text: "SAP CX (C4/HANA)", value: "#CX" },
  { text: " SAP FIORI", value: "#FIORI" },
  { text: "SAP CLOUD PLATFORM", value: "#SCP" },

    ],
    productos: [],
    sectores: [
      { text: "Cliente 1", value: "#agro" },
      { text: "Cliente 2", value: "#retail" },
      { text: "Cliente 3", value: "#produccion" },
      { text: "Cliente 4", value: "#salud" },
    ],
    cierresplegables: [
      { text: "Cierre Plegables para Balcón (Departamentos)", value: "#acerca" },
      { text: "Cierres Plegables para Quinchos (Casas) ", value: "#quienes" },
      { text: "Cierre Plegable Acusticos (Termo Panel)", value: "#que" },
      { text: "Ventanas de Cristal Tradicionales", value: "#sectores" },
    ],
    soporte: [],
    contacto: [],
user:null,
    drawer: false,
  

    //
  }),

  created() {

firebase.auth().onAuthStateChanged((user)=>{
if (user){this.user = user} else this.user = null})

// Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }



    db.collection("web")
      .doc("Servicios Sap")
      .get()
      .then((task) => {
        let document = task.data();
        if (document != null) {
          document.soluciones.forEach((setMap) => {
            console.log(setMap);
          });
        }
      });

    db.collection("web")
      .doc("Nosotros")
      .get()
      .then((task) => {
        let document = task.data();
        if (document != null) {
          document.Nosotros.forEach((setMap) => {
            console.log(setMap);
          });
        }
      });

    db.collection("web")
      .doc("Sectores")
      .get()
      .then((task) => {
        let document = task.data();
        if (document != null) {
          document.Sectores.forEach((setMap) => {
            console.log(setMap);
          });
        }
      });

    db.collection("web")
      .doc("Soporte Sap")
      .get()
      .then((task) => {
        let document = task.data();
        if (document != null) {
          document.Sectores.forEach((setMap) => {
            console.log(setMap);
            this.sectores.push(setMap);
          });
        }
      });
  },

  computed: {



    width() {
      return this.$vuetify.breakpoint.width * 0.25;
    },

    height() {
      return this.width / 1.77;
    },


    activeFab() {
      switch (this.tabs) {
        case "one":
          return { color: "success", icon: "mdi-share-variant" };
        case "two":
          return { color: "red", icon: "mdi-pencil" };
        case "three":
          return { color: "green", icon: "mdi-chevron-up" };
        default:
          return {};
      }
    },
  },
  methods: {

    logout(){firebase.auth().signOut().then(()=>{

this.$$router.push({name:'Home'})


    }    )},

signup() { let ref = db.collection("users").doc(this.email);
   ref.get().then(doc => {
          if (doc.exists) {
        
            this.feedback = "Este email ya esta en uso, te debes logiar";
          } else {
              firebase
              .auth()
              .createUserWithEmailAndPassword(this.email, this.password)
              .catch(err=>{console.log(err)
                this.feedback= err.message})
.then(() => {
          let ref = db.collection("users").doc(this.email);
       
          ref.set(
            {
              email: this.email
    
            },
            { merge: true }
          );
        })
 


        this.feedback = "Cuenta ha sido creada, ahora solo te debes logiar";      
          
          
     
          }
        });





},
signin() {
      if (this.email) {
       
        let ref = db.collection("users").doc(this.email);
        ref.get().then(doc => {
          
          if (doc.exists) {

             this.feedback = "doc exist";
            firebase
              .auth()
              .signInWithEmailAndPassword(this.email, this.password)
              .then(()=> {

                  this.$router.push({
            name: "Cliente",
            params: {
              email: this.email,
           
            },
          });

              })
           
              .catch(err => {
                console.log(err);
                this.feedback = err.message;
              })
 .then(() => {

 this.dialog = false;

            this.feedback = "login ok"
        });  
          } else {
            this.feedback = "email esta disponible";
          }
        });
      } 
      
      else {
        this.feedback = "Completar todos los campos";
      }
    },

    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = 'Refresca la web';
      this.snackWithBtnText = 'Nueva version disponible!';
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },


    link(a) {
      this.$router.push({
        name: a,
      });
    },

    signingoogle() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((cred) => {
          let ref = db.collection("users").doc(cred.user.email);
          this.email = cred.user.email;
          ref.set(
            { name :cred.user.displayName,
              email: cred.user.email,
              user_id: cred.user.uid,
              creacion_tiempo: cred.user.metadata.creationTime,
              sign_tiempo: cred.user.metadata.lastSignInTime,
              proIndex: [],
            },
            { merge: true }
          );
        })

        .then(() => {

 this.dialog = false;
 

          this.$router.push({
            name: "Cliente",
            params: {


              email: this.email,
              life: this.life,
              foto: this.foto,
              name: this.firstName + this.lastName,
            },
          });
        });
    },
    cerrar() {
      this.dialog = false;
      this.dialog1 = false;
      this.dialog2 = false;
      this.dialog3 = false;
      this.dialog4 = false;
    },
  },
};
</script>
<style>
   #landing-page {
  
     background-color: black; 
  
  }
</style>