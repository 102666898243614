<template>
  <v-container>
       <v-divider ></v-divider>
    <v-layout
      row
      justify-space-around
      wrap
      align-center
    >
      <v-flex
        xs12
        sm6
        align-center
      ><br /><br />
        >
        <v-img
          class="white--text align-center"
          src="https://www.evolve-it.com.mx/wp-content/uploads/2013/04/sap.png"
        >
        </v-img>
      </v-flex>
      <BR />
      <v-flex
        xs12
        sm5
      >
        <v-container>
          <BR />
          <h3 class="text-center display-1 font-weight-light blue--text mb-1">
            MÓDULOS ADICIONALES (ADDONS)
          </h3>
          <br />

          <h3 class="text-justify  Subtitle-1 font-weight-light black--text title mb-1">

            El AddOn es un desarrollo específico para cumplir con los requerimientos empresariales, agregando nuevas funciones a su ERP SAP.
          </h3>

          <BR />
          <h3 class="text-justify  Subtitle-1 font-weight-light black--text title mb-1">
            En ocasiones un ERP nativo no es suficiente para cumplir con los requerimientos empresariales, por lo que la adquisición de un Add-On fortalecerá las funcionalidades y los procesos específicos.

          </h3>

        </v-container>
      </v-flex>
    </v-layout>
    <br />
  
    
    <v-flex
      xs12
      sm12
    >
      <h3 class="text-center display-1 font-weight-light blue--text mb-1">
       CONOCE NUESTROS MÓDULOS
      </h3>
      <br />



  <v-row justify="center">
    <v-expansion-panels accordion>
      <v-expansion-panel
        v-for="(a,i) in  modulos"
        :key="i"
      >
        <v-expansion-panel-header >       <v-row justify="center" align="start">
      <v-card
            class="pa-2"
            outlined
            tile
          >
           <v-icon
      large
      color="info"
    >
{{a.p}}
    </v-icon>      {{a.item}}
          </v-card>
      <p class="text-left" >
   
      </p>
   
          
  </v-row>

     
          
            </v-expansion-panel-header>
   
        <v-expansion-panel-content>
         {{a.Descripción}}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>




     
    </v-flex>

  </v-container>
</template>

<script>
import db from "@/firebase/init.js";
import firebase from "firebase";
export default {
  name: "ADONS",
  components: {},
  props: [],

  data() {
    return {
      productos: "",
      areas: "",
      funcion: "",
      foto2: "",
      descripcion: "",
      titulo: "",
      mensaje: "",
      modulos: [
        { item: "Nomina",Titulo: "Nómina para SAP Business One", Descripción: "El módulo de Nómina para SAP Business One es configurable. El usuario puede realizar cambios a la aplicación de forma sencilla conforme cambian las necesidades de la organización. Exportación de información a Excel y Word. Integración directa con el SUAwin y con equipos de control de asistencia (relojes checadores) Manejo de fórmulas. Fácil configuración de los cálculos de cada concepto a cargo del usuario final con sintaxis similar a la de Excel. Datos generales del empleado.", p: "mdi-clipboard-text", a: 50 },

        { item: "Gestión de Almacenes ",Titulo: "Gestión de Almacenes",Descripción: "Mejora la visibilidad del inventario y reduce sus costos asociados. Proporciona información confiable y en tiempo real con respecto a las entradas, movimientos internos en el almacén y las salidas llegando a tener controles básicos como almacén, zona o ubicación (bin). Gestión de almacén. Dentro de las funciones de gestión de almacén se encuentran los conteos cíclicos, los movimientos de inventario por ajustes u operativos, transferencias, inventarios físicos y optimización del almacén. Productividad. Se eleva la productividad del personal del almacén por los procesos sugeridos por el sistema como el almacenamiento, picking y conteos cíclicos.", p: "mdi-package-variant", a: 5 },
        { item: "Portal de Proveedores",Titulo: "Portal de Proveedores",Descripción: "Extracción de información desde SAP (pedidos, entregas, estatus de pagos, documentos contables, datos del proveedor). Recepción de facturas de Bienes y Servicios (identificado por tipo de pedido, o por clave de material). Recepción de factura de anticipos (configurado en cabecera del pedido). Facturas con retenciones (requiere ajuste de campos de usuario). Generación automática de documentos en SAP (factura de proveedores). Consulta de las facturas cargadas en el portal.", p: "mdi-television-guide", a: 2 },
        {
          item: "Punto de Venta",
         Titulo: "Punto de Ventas",
         Descripción: "La aplicación soporta ventas en esquema mostrador y en esquema autoservicio y no tiene limitantes por el número de transacciones o número de cajas ya que son independientes de la base de datos principal del ERP.",
          p: "mdi-store",
          a: "si",
        },
       
      ],
    };
  },
  created() {
    var docRef = db.collection("web").doc("Servicios Sap");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().AF1;
          this.foto2 = doc.data().AF0;
          this.titulo = doc.data().BN1;
          this.mensaje = doc.data().CM0;
          this.descripcion = doc.data().DD1;
          this.funcion = doc.data().EF0;
          this.funciones = doc.data().EF1;
          this.areas = doc.data().FB1;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "230px";
        case "sm":
          return "290px";
        case "md":
          return "363px";
        case "lg":
          return "435px";
        case "xl":
          return "600px";
      }
    },

    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "290px";
        case "sm":
          return "320px";
        case "md":
          return "4200px";
        case "lg":
          return "610px";
        case "xl":
          return "650px";
      }
    },
  },
};
</script>
