<template   >

  <v-app   class="black"    >




<div class="text-center">
      <v-dialog
        v-model="dialogACTIVE"
        width="500"
      
      >
        <template v-slot:activator="{ on, attrs }">
      
        </template>
  
        <v-card  height="600"   color="black">
        
  
<iframe src="https://valoradesign.activehosted.com/f/1/" width="100%" height="700" frameborder="0"></iframe>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              text
              @click="dialog = false"
            >
             Volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

</div >






  <v-row justify="center">
      <v-dialog
        v-model="dialog"
        scrollable
     :width="width*0.80"
      >
        <template >
     <v-card
          
        
        >
          <v-card-title>VISION</v-card-title>
          <v-divider></v-divider>
        <v-responsive
      class="overflow-y-auto"
     
  v-for="(social, i) in vision"
                  :key="i"

    >
      
  
      <v-responsive
        height="200vh"
        class="text-center pa-2"
      >
     
        
           <v-card
        class="white justify-center"
        :width="width*0.8"
      outlined
    >
       <v-layout row justify-space-around wrap align-center>
     
         <v-flex xs12 sm7>
        <v-container>
         
          <h3 class="text-center display-1 font-weight-light black--text mb-1">
       VISION  
          </h3>
          <br />
        
          <h3


          
     class="text-justify   text-xs-h6           [1] Subtitle-1 font-weight-light black--text title mb-1"
          >


       
  {{ social.Texto}}
     <br />  <br />
 {{ social.Texto2}}
          </h3>
        </v-container>
      </v-flex>
     
      <v-flex xs12 sm5 align-center
        ><br /><br />
        
         <iframe
         
    
          :src="social.videodetalle"
          frameborder="0"
          scrolling="no"
           
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        width="400"
  height="260"    
        >
        </iframe>


         <br /><br />
        
         <iframe
  
          :src="social.videodetalle2"
          frameborder="0"
          scrolling="no"
           
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        width="400"
  height="260"    
        >
        </iframe>
      </v-flex>
            
                     <br />          <br />
                     <br />          <br />
               
    </v-layout>
      
           
<v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Volver
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Conoce que piensan nuestros clientes
            </v-btn>
          </v-card-actions>
  
  <div id="app">


  </div>
      
    </v-card>
       
      </v-responsive>
    </v-responsive>
    

          
        </v-card>
        </template>
     
      </v-dialog>
    </v-row>





  <v-row justify="center">
      <v-dialog
        v-model="dialog2"
        scrollable
     :width="width*0.80"
      >
        <template >
     <v-card
          
        
        >
          <v-card-title>COTIZA VISION</v-card-title>
          <v-divider></v-divider>
        <v-responsive
      class="overflow-y-auto"
     
  v-for="(social, i) in vision"
                  :key="i"

    >
      
  
      <v-responsive
        height="200vh"
        class="text-center pa-2"
      >
     
          <v-card color="white">
                  <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                  >
                    Primero debemos <br />
                    confirmar tus datos.
                  </v-card-title>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        required
                        disabled
                        v-model="name"
                        color="blue-grey lighten-1"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        required
                        disabled
                        v-model="email"
                        color="blue-grey lighten-1"
                        label="Email:"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        required
                        v-model="Adress"
                        type="text"
                        filled
                        color="blue-grey lighten-1"
                        label="Indícanos tu Dirección"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        required
                        disabled
                        v-model="CoLargo"
                        type="text"
                        color="blue-grey lighten-1"
                        label="Comunidad - Condominio"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        required
                        v-model="Telefono"
                        type="text"
                        filled
                        color="blue-grey lighten-1"
                        label="Indícanos tu Teléfono: Ej. (+569 83400795)"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-chip
                    label
                    color="primary"
                    text-color="white"
                    size="sm"
                    class="mx-1 mt-1"
                    @click="add2"
                  >
                    <strong>OK</strong>
                  </v-chip>
                  <v-card-text> </v-card-text>
                </v-card>
       
       
      </v-responsive>



      
    </v-responsive>
    

          
        </v-card>
        </template>
     
      </v-dialog>
    </v-row>
--




    <p align="center">
<router-link to="/">

            <v-img
              alt="Rain"
              class="shrink mr-2"
              contain
              src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/valoradesignlogo.png?alt=media&token=5daa9ca6-db00-4363-8a74-33813532fcf0"
              transition="scale-transition"
           :width="width*0.6"
            />
</router-link>
   <v-flex xs12 >
        <h1 class="text-center font-weight-thin white--text mb-1    
        
        text-h5            [1]
        text-md-h4         [2]
        text-lg-h4         [2]
        text-xl-h4         [2] ">
     ¡Haz que tu Hogar Hable de Ti!


             <br />
Atrévete a innovar!
        </h1>

 

        <br />
        <br />
      </v-flex>



</p>

    <p align="center">
    <v-card
        class="grey justify-center"
       :width="width*0.87"
      tile
      dark
   
    >

   
      <v-container
      
        :key="type"
        class="black"
        fluid
      >

        <v-row
        
        >
  
          <v-col
          
       v-for="(social, i) in socials"
                  :key="i"
            cols="12"
            sm="12"
            md="12"
          >
            <v-card
         class="white justify-center"
          
            >


            <br> <br>
            <p align="center">
   <v-card-actions class="Black justify-center">
                 <h1 class="text-center display-1 font-weight-light  black--text mb-1    
        
        text-xs-h5            [1]
        text-md-h4         [2]
        text-lg-h4         [2]
        text-xl-h4         [2] ">
      {{ social.titulo}}


             <br /><br> 

        </h1>
              </v-card-actions>
     <v-row
      align="center"
      justify="space-around"
    >

      <v-btn depressed
  @click="dialogACTIVE=true"
      
      >
           <v-icon left>
          mdi-pencil
        </v-icon>
        Cotizar    {{menu}}
      </v-btn>

     <v-btn 
  
    
     :href="social.link"
     
     >
             <v-icon left>
    mdi-book-open
        </v-icon>   Info

      </v-btn>

  
    </v-row>



   <br /><br> 

  
      
        <BR/>
       
        
     

    
  
             <iframe
         
     
          :src="social.video"
          frameborder="0"
          scrolling="no"
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="width*0.8"
  :height="width*0.45"    
        >
        </iframe>
        </p>
  
     
     <br>
            </v-card>
           
          </v-col>
        </v-row>

         
      </v-container>


    </v-card>



     </p>

    <v-row fluid inicio align-center justify="center" @click.native.stop>


            <v-card   v-if="width<750"  :width="width*1.1" color="grey" height="50">
             
<div  >
   <v-tabs fixed-tabs background-color="black" dark>
          <v-tab to="/nososotros#acerca">
            Acerca de Rain
          </v-tab>
          <v-tab to="/servicios#ANA">
            Nuestros Servicios
          </v-tab>
        </v-tabs>
</div>
</v-card>

<v-row inicio align-center justify="center"  v-if="width>750" @click.native.stop>
      <v-col class="text-center" cols="12" xs="12">
       

       
      <v-tabs fixed-tabs background-color="black" dark>
          <v-tab to="/nososotros#acerca">
            Acerca de Rain
          </v-tab>
          <v-tab to="/servicios#ANA">
            Nuestros Servicios
          </v-tab>
        </v-tabs>
        
        <v-tabs fixed-tabs background-color="black" dark>
          <v-tab>
           Clientes
          </v-tab>
          <v-tab>
           Blog
          </v-tab>
        </v-tabs>

        
      </v-col>

    </v-row>
  </v-row>

  </v-app>

</template>

<script>

import db from "../firebase/init.js";
import firebase from "firebase";


export default {
  name: "HelloWorld",

  components: {  
        },
  props: [],

  data() {
    return {

dialogACTIVE:false,

      name:'',
Adress: null,
      Telefono: null,
      Registra: false,
      todo: false,
  

   dialogm1: '',
      dialog: false,
        dialog2: false,
 tab: null,
      diferenciadores: [
       {nombre:"Vision",texto:"Contamos con Certificaciones SAP de los servicios que ofrecemos"}, 
        {nombre:"Comentarios de Clientes",texto:"Conocimiento y experiencia en áreas funcionales y técnicas."},
         
       
       
      ],
      
 

 youtubeLink:[{video:'https://www.youtube.com/watch?v=fgC-swCyyyw' },{video:'https://www.youtube.com/watch?v=xSYQxk34L2Q' }, 
  {video:'https://www.youtube.com/watch?v=shytjg4p6W8' },{video:'https://www.youtube.com/watch?v=YS855hDF3ac' }, 
  {video:'https://www.youtube.com/watch?v=9aJDo9sVG8I' },{video:'https://www.youtube.com/watch?v=Bj2v94SqVCc' }, 
  {video:'https://www.youtube.com/watch?v=Xcrf17h8xsU' },{video:'https://www.youtube.com/watch?v=pBFtRig9PUQ' }, {
    video:'https://www.youtube.com/watch?v=pEue0tLnLH4' },  {video:'https://www.youtube.com/watch?v=tfOKHzQejHg' },
    {video:'https://www.youtube.com/watch?v=tSfd8aVTEBw' },  {video:'https://www.youtube.com/watch?v=mq3rf-ONR60' },
  ],
     

      
      vimeoLink:'https://player.vimeo.com/video/64654583',
menu:null,
 types: ['Places to Be', 'Places to See'],
    cards: ['Good', 'Best', 'Finest' ],
    
    
    vision:[

       {titulo:"1. Cierre de Cristal Plegable para Balcón",
        icon: 'mdi-facebook',
        color: 'indigo',
 youtubeLink:['https://www.youtube.com/watch?v=TcMBFSGVi1c',  ],
        videodetalle2: '//fast.wistia.net/embed/iframe/fn37tx8ja6',
        videodetalle: '//fast.wistia.net/embed/iframe/i0ipmvsy30',
        video: '//fast.wistia.net/embed/iframe/dz2c0ly48d',
        Texto:"Sistema que utiliza una hoja abatible en el extremo del vano, para permitir la salida y apilamiento de las hojas que corren  horizontalmente en el vano. Usted podrá transformar su terraza en el espacio soñado, con mayor versatilidad, confort y seguridad, protegiéndose del viento, lluvia, polvo y frió. Su belleza estética complementa la decoración, añadiendo valor a su propiedad. incorpora Cristales Templados de seguridad lo cual asegura una adecuada resistencia a la filtraciones.",
       Texto2:"Único sistema con burletes invisibles entre cristales, especial para sectores de playa.Permite incorporar opcionalmente Cortinas Roller Control Solar. (Click para conocer nuestras colecciones).     Colores de Perfilería: Blanco",
      },  ],
    socials: [

       {titulo:"1. Cierre de Cristal Plegable para Balcón ",
        icon: 'mdi-facebook',
        color: 'indigo',
        link:"https://valoradesign.cl/cierredecristal#vision",
 youtubeLink:['https://www.youtube.com/watch?v=TcMBFSGVi1c',  ],
        videodetalle2: '//fast.wistia.net/embed/iframe/fn37tx8ja6',
        videodetalle: '//fast.wistia.net/embed/iframe/i0ipmvsy30',
        //dz2c0ly48d
        video: '//fast.wistia.net/embed/iframe/i0ipmvsy30',
        Texto:"Sistema que utiliza una hoja abatible en el extremo del vano, para permitir la salida y apilamiento de las hojas que corren  horizontalmente en el vano. Usted podrá transformar su terraza en el espacio soñado, con mayor versatilidad, confort y seguridad, protegiéndose del viento, lluvia, polvo y frió. Su belleza estética complementa la decoración, añadiendo valor a su propiedad. incorpora Cristales Templados de seguridad lo cual asegura una adecuada resistencia a la filtraciones.",
       Texto2:"Único sistema con burletes invisibles entre cristales, especial para sectores de playa.Permite incorporar opcionalmente Cortinas Roller Control Solar. (Click para conocer nuestras colecciones).     Colores de Perfilería: Blanco",
      },
       {

        link:"https://valoradesign.cl/cierreplegableterraza#vision_Piso_Cielo",
        titulo:"2. Cierre de Cristal Plegable para Terrazas",
        icon: 'mdi-facebook',
        color: 'indigo',
        video: '//fast.wistia.net/embed/iframe/qvdixtlmme',
      },

 {link:"https://valoradesign.cl/cierre_plegable_termo#vision_termo",
         titulo:"3. Cierre de Cristal Plegables Termo Panel",
        icon: 'mdi-linkedin',
        color: 'cyan darken-1',
        video: '//fast.wistia.net/embed/iframe/56cdl1lcwr',
      },

      {link:"https://valoradesign.cl/collection#SCREEN",
         titulo:"4. Cortinas y Persianas",
        icon: 'mdi-linkedin',
        color: 'cyan darken-1',
        video: '//fast.wistia.net/embed/iframe/dkfj18jatt',
      },
      {      link:"",
      titulo:"5. Casa Inteligente",
        icon: 'mdi-instagram',
        color: 'red lighten-3',
             video: '//fast.wistia.net/embed/iframe/gv6zughdpk',
  
      },
    ],







items: [
      {
        src: 'backgrounds/bg.jpg',
      },
      {
        src: 'backgrounds/md.jpg',
      },
      {
        src: 'backgrounds/bg-2.jpg',
      },
      {
        src: 'backgrounds/md2.jpg',
      },
    ],
    selected: [],

      cardss: [
        {
          title: "Pre-fab homes",
          src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          flex: 4,
        },
        {
          title: "Favorite road trips",
          src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          flex: 4,
        },
        {
          title: "Best airlines",
          src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
          flex: 4,
        },
      ],
      value: 1,
      email: null,
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  created() {
    db.collection("bejarcarlos@gmail.com").onSnapshot((snapshot) => {
      snapshot.forEach((userSnapshot) => {
        this.email = userSnapshot.data().email;
      });
    });
  },
  methods: {





 handleClick(event, ref) {
      this.$refs[ref][event]()
    },
    handleSearch(e, platform) {
      if(platform === 'youtube')
        this.youtubeLink = e.target.value;
      else
        this.vimeoLink = e.target.value;
    },
    getImage () {
      const min = 550
      const max = 560

      return   (max - min + 1) + min
    },

   signingoogle() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((cred) => {
          let ref = db.collection("users").doc(cred.user.email);
          this.email = cred.user.email;
          ref.set(
            {
              name: cred.user.displayName,
              email: cred.user.email,
              user_id: cred.user.uid,
              creacion_tiempo: cred.user.metadata.creationTime,
              sign_tiempo: cred.user.metadata.lastSignInTime,
              proIndex: [],
            },
            { merge: true }
          );
        })

        .then(() => {

 this.dialog = false;
 

          this.$router.push({
            name: "Cliente",
            params: {


              email: this.email,
              life: this.life,
              foto: this.foto,
              name: this.firstName 
            },
          });
        });
    },



   
  },

  computed: {
    color() {
      switch (this.value) {
        case 0:
          return "blue-grey";
        case 1:
          return "black";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },


breaknName(){

      return  this.$vuetify.breakpoint.name

},

    width() {
      return this.$vuetify.breakpoint.width ;
    },

    height() {
      return this.width / 1.77;
    },
  },
};
</script>
