<template>



    <v-row>



  
     

      <br/>  <br/>      <br/>       
      
      
      
      <v-col
         v-for="(social, i) in youtubeLink"
                  :key="i"
        class="d-flex child-flex"
        cols="6"
      >
       
    <LazyYoutube
       
        :src="social.video"
        min-min="300"
        max-width="500x"
        aspect-ratio="16:9"
        thumbnail-quality="standard"
        preview-image-size="maxresdefault"
    />
      </v-col>
    </v-row>
   



</template>

<script>
import db from "../firebase/init.js";
import firebase from "firebase";
import { LazyYoutube,  } from "vue-lazytube";

export default {
  name: "cierre",

  components: {  LazyYoutube, 
        },
  props: [],

  data() {
    return {

 tab: null,
      diferenciadores: [
       {nombre:"Equipo Certificado",texto:"Contamos con Certificaciones SAP de los servicios que ofrecemos"}, 
        {nombre:"Experiencia",texto:"Conocimiento y experiencia en áreas funcionales y técnicas."},
          {nombre:"Flexibilidad",texto:"Flexibilidad y adaptación a las metodologías utilizadas por nuestros clientes."},
                {nombre:"Relaciones",texto:"Buscamos la creación de relaciones a largo plazo con nuestros clientes."},
       
       
      ],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

 capacidades: [

   { item: "MODELAMIENTO DE PROCESOS DE NEGOCIO ",Titulo: "MEJORA CONTINUA ",Descripción: "Apoyado de nuestra capacidad de entender de forma rápida y acertada los procesos de negocios, podemos modelar e implementar soluciones que satisfagan las expectativas de nuestros clientes.", p: "", a: 5 },
       
       {
          item: "OPTIMIZACIÓN DE PROCESOS TÉCNICOS",
         Titulo: "ASESORÍAS Y ASEGURAMIENTO DE CALIDAD",
         Descripción: "Contamos con un área técnica especializada en verificación de performance, mejoramiento y optimización de ampliaciones al sistema.",
          p: "",
          a: "si",
        },

        
        { item: "AUTOMATIZACIÓN DE PROCESOS",Titulo: "MESA DE AYUDA Y SOPORTE",Descripción: "Apoyado de nuestra capacidad de funcional y técnica buscamos automatizar procesos complejos de nuestros clientes ", p: "", a: 2 },
        {
          item: "INNOVACIÓN Y DESARROLLO",
         Titulo: "SOFTWARE FACTORY",
         Descripción: "Contamos con un área de innovación y desarrollo que constantemente está enfocándose en la investigación de nuevas tecnologías y como usarlas para solucionar problemas de nuestros clientes.",
          p: "",
          a: "si",
        },
       
       {
          item: "REINGENIERÍA DE PROCESOS ",
         Titulo: "CAPACITACIÓN",
         Descripción: "Apoyado de nuestra capacidad funcional y el conocimiento de diferentes industrias buscamos y simplificar y los procesos complejos de nuestros clientes ",
          p: "",
          a: "si",
        },
           
               { item: "EXPERIENCIA",Titulo: "PROYECTOS DE IMPLEMENTACIÓN", Descripción: "Apoyado de nuestra experiencia y conocimiento en proyectos a nivel nacional e internacional buscamos entregar la mejor experiencia y satisfacción al cliente en la implementación de soluciones SAP que ayuden a mejorar su negocio.", p: "", a: 50 },

             
       
      ],

  servicios: [

     {
          item: "ASESORÍAS Y ASEGURAMIENTO DE CALIDAD",
         Titulo: "ASESORÍAS Y ASEGURAMIENTO DE CALIDAD",
         Descripción: "Nuestros servicios buscan apoyar las implementaciones a empresas que no tienen un profundo conocimiento para lograr una exitosa implementación entregando nuestros servicios de levantamiento de información y apoyo en la construcción del Business Blue Print, apoyo en las pruebas unitarias/Integrales, revisión y construcción de especificaciones funcionales, revisión y construcción de especificaciones técnicas, análisis y posibles soluciones a requerimientos fuera de alcance y su construcción.",
          p: "",
          a: "si",
        },
        { item: "PROYECTOS DE IMPLEMENTACIÓN",Titulo: "PROYECTOS DE IMPLEMENTACIÓN", Descripción: "Apoyado de nuestra experiencia y conocimiento y de las metodologías de proyectos (ASAP, SAP Active, Agile) buscamos entregar la mejor experiencia en la implementación de proyectos para apoyar el crecimiento de su empresa", p: "mdi-worker", a: 50 },

        { item: "MESA DE AYUDA Y SOPORTE",Titulo: "MESA DE AYUDA Y SOPORTE",Descripción: "Mesa de ayuda y soporte para la solución de incidentes a fin de que su negocio continúe en operando, utilizando un SLA definido dependiendo del tipo de plan o paquete que sea gestionado.", p: "", a: 2 },
           {
          item: "SOFTWARE FACTORY",
         Titulo: "SOFTWARE FACTORY",
         Descripción: "Fábrica de desarrollo que permite gestionar los desarrollo con nuestro equipo especialistas dependiendo del tipo de plan o paquete que sea gestionado.",
          p: "",
          a: "si",
        },
                { item: "MEJORA CONTINUA ",Titulo: "MEJORA CONTINUA ",Descripción: "Apoyado de nuestra experiencia y conocimiento ayudamos a implementar soluciones innovadoras para mejorar los procesos de su empresa integrando diferentes productos SAP y No SAP.", p: "", a: 5 },
 
        {
          item: "CAPACITACIÓN ",
         Titulo: "CAPACITACIÓN",
         Descripción: "Capacitaciones, cursos, e inducciones de las soluciones que ofrece nuestra empresa para que se empodere de su sistema, y vea las funcionalidades que entrega cada producto, y las futuras funcionalidades",
          p: "",
          a: "si",
        },
        
            
       
      ],

      
 youtubeLink:[{video:'https://www.youtube.com/watch?v=dU20K5a1Yek' },{video:'https://www.youtube.com/watch?v=NSrB92E3III' },{video:'https://www.youtube.com/watch?v=fgC-swCyyyw' },{video:'https://www.youtube.com/watch?v=xSYQxk34L2Q' }, 
  {video:'https://www.youtube.com/watch?v=shytjg4p6W8' },{video:'https://www.youtube.com/watch?v=YS855hDF3ac' }, 
  {video:'https://www.youtube.com/watch?v=9aJDo9sVG8I' }, 
  {video:'https://www.youtube.com/watch?v=Xcrf17h8xsU' },{video:'https://www.youtube.com/watch?v=pBFtRig9PUQ' }, {video:'https://www.youtube.com/watch?v=t5xEwvyUYsE' }, {video:'https://www.youtube.com/watch?v=6VOqbNsJRng' }, {video:'https://www.youtube.com/watch?v=Ozvn8zhzIxs' }, {video:'https://www.youtube.com/watch?v=1dygwSDTNPc' }
    
  ],
     

      
      vimeoLink:'https://player.vimeo.com/video/64654583',
menu:null,
 types: ['Places to Be', 'Places to See'],
    cards: ['Good', 'Best', 'Finest' ],
    socials: [

       {titulo:"1. Cierre de Balcón VISION ",
        icon: 'mdi-facebook',
        color: 'indigo',
 youtubeLink:['https://www.youtube.com/watch?v=TcMBFSGVi1c',  ],
        videodetalle2: '//fast.wistia.net/embed/iframe/fn37tx8ja6',
        videodetalle: '//fast.wistia.net/embed/iframe/i0ipmvsy30',
        video: '//fast.wistia.net/embed/iframe/dz2c0ly48d',
        Texto:"Sistema que utiliza una hoja abatible en el extremo del vano, para permitir la salida y apilamiento de las hojas que corren  horizontalmente en el vano. Usted podrá transformar su terraza en el espacio soñado, con mayor versatilidad, confort y seguridad, protegiéndose del viento, lluvia, polvo y frió. Su belleza estética complementa la decoración, añadiendo valor a su propiedad. incorpora Cristales Templados de seguridad lo cual asegura una adecuada resistencia a la filtraciones.",
       Texto2:"Único sistema con burletes invisibles entre cristales, especial para sectores de playa.Permite incorporar opcionalmente Cortinas Roller Control Solar. (Click para conocer nuestras colecciones).     Colores de Perfilería: Blanco",
      },
      {
        titulo:"2. Cierre de Quincho TAURO",
        icon: 'mdi-facebook',
        color: 'indigo',
        video: '//fast.wistia.net/embed/iframe/qvdixtlmme',
      },
      {
         titulo:"3. Cortinas y Persianas",
        icon: 'mdi-linkedin',
        color: 'cyan darken-1',
        video: '//fast.wistia.net/embed/iframe/dkfj18jatt',
      },
      {      titulo:"4. Renovación Piso Baño y Cocina",
        icon: 'mdi-instagram',
        color: 'red lighten-3',
             video: '//fast.wistia.net/embed/iframe/gv6zughdpk',
  
      },
 {      titulo:"4. Renovación Piso Baño y Cocina",
        icon: 'mdi-instagram',
        color: 'red lighten-3',
             video: '//fast.wistia.net/embed/iframe/gv6zughdpk',
  
      },


     
    ],




items: [
      {
        src: 'backgrounds/bg.jpg',
      },
      {
        src: 'backgrounds/md.jpg',
      },
      {
        src: 'backgrounds/bg-2.jpg',
      },
      {
        src: 'backgrounds/md2.jpg',
      },
    ],
    selected: [],

      cardss: [
        {
          title: "Pre-fab homes",
          src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          flex: 4,
        },
        {
          title: "Favorite road trips",
          src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          flex: 4,
        },
        {
          title: "Best airlines",
          src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
          flex: 4,
        },
      ],
      value: 1,
      email: null,
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  created() {
    db.collection("bejarcarlos@gmail.com").onSnapshot((snapshot) => {
      snapshot.forEach((userSnapshot) => {
        this.email = userSnapshot.data().email;
      });
    });
  },
  methods: {

 handleClick(event, ref) {
      this.$refs[ref][event]()
    },
    handleSearch(e, platform) {
      if(platform === 'youtube')
        this.youtubeLink = e.target.value;
      else
        this.vimeoLink = e.target.value;
    },
    getImage () {
      const min = 550
      const max = 560

      return   (max - min + 1) + min
    },




    signingoogle() {
      let provider = new firebase.auth.GoogleAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)

        .then((cred) => {
          let ref = db.collection(cred.user.email).doc(cred.user.email);
          //let ref2 = db.collection("Comunidad").doc("Condos");

          //ref2.set(
          // {
          // Condos: this.Condos,

          //     },

          // );

          this.email = cred.user.email;

          ref.set(
            {
              email: cred.user.email,
              user_id: cred.user.uid,
              creacion_tiempo: cred.user.metadata.creationTime,
              sign_tiempo: cred.user.metadata.lastSignInTime,
              proIndex: [],
            },
            { merge: true }
          );
        })

        .then(() => {
          this.$router.push({
            name: "About",
            params: {
              email: this.email,
              life: this.life,
              foto: this.foto,
              name: this.name,
            },
          });
        });
    },
  },

  computed: {
    color() {
      switch (this.value) {
        case 0:
          return "blue-grey";
        case 1:
          return "black";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },


breaknName(){

      return  this.$vuetify.breakpoint.name

},

    width() {
      return this.$vuetify.breakpoint.width ;
    },

    height() {
      return this.width / 1.77;
    },
  },
};
</script>