<template>

  <v-app class="black" >


      <v-container
  
       
      >
 <v-dialog
        v-model="dialog"
        width="500"
      >
       
  
        <v-card>
        
  
          <v-card-text>
Muchas Gracias!!,  <br />   
                Hemos recibido tus datos. <br />   
              <br />   
              <br />   
Revisa la bandeja de Notificaciones o Promociones
           </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
             to='/'
            >
              volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

        <!-- carta 1 -->

        <v-row
          v-if="cart"
          align="center"
          justify="center"
        >
          <v-col class="text-center">

          

            <v-row justify="center">
   
              <v-row justify="center">
                <v-col
                  cols="10"
                  md="5"
                >


                  <v-col
              cols="12"
              md="12"
            >
              <h3 class="text-justify  Subtitle-1 font-weight-light white--text title mb-1">

Cotiza Cierre de Cristal Plegable ValoraDesign
  
              </h3>

        
            </v-col>


              <br />         

                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >

                  <v-text-field
                         required
                    dark
                color="white"
                   prepend-inner-icon="mdi-email  "
                      background-color="grey"
                        class="shrink mr-2"
                      v-model="email"
                     height="50"
                      label="Correo"
               max-width="444"
                    ></v-text-field>


                     <v-text-field
                     required
                    dark
                color="white"

               :rules="TelefonoRules"
                   prepend-inner-icon=" mdi-cellphone-iphone"
                      background-color="grey"
                        class="shrink mr-2"
                      v-model="Telefono"
                      type="text" id="phone" placeholder="Ej. +569 77480204"
                       autofocus
                     height="40"
                      label="Indicanos tu telefono"
               max-width="444"
                    ></v-text-field>

                              <v-autocomplete


               :rules="ComunaRules"
                                     required
                                   background-color="grey"
                         dark
                                
                                loader-height="2"
                                allow-overflow="false"
                             
                                prepend-inner-icon="mdi-google-maps"
                 
                                cache-items
                                open-on-hover="true"
                           
                                clearable
                                deletable-chips
                                default
                              
                     label="Indicanos tu Comuna"
              v-model="selections"
              
                                :items="Condominio"
                                chips
                              ></v-autocomplete>



        <v-select

  prepend-inner-icon="mdi-image-area"
        dark
     
         background-color="grey"
         v-model="select"
          :items="sinRepetidosModulo"
          filled
          color="white"
          label="Selecione Tipo de Vano"
           :width="width*0.3"
        ></v-select>

   

<br />  


    <v-row
        class="mb-4"
        no-gutters
     color="black"
      >
        <v-col
          
        >
          <v-card
            class="pa-2"
            outlined
            tile
             color="black"
          >
            <v-text-field
            color="white"
   dark
     v-if="select"
     background-color="grey"
    loading
  label="Indicar Ancho "
            auto-grow
            outlined

               :rules="numberRules"
                suffix="Metros"

          v-model="ancho1"
          filled
  
       
        ></v-text-field>
          </v-card>
        </v-col>
        
         <v-col

        >
          <v-card
            class="pa-2"
            outlined
            tile
            color="black"
          >
         <v-text-field
 dark
              v-if="select"
             background-color="grey"
 loading
  label="Indicar Alto "
            auto-grow
            outlined
            rows="1"
            row-height="15"
             :rules="numberAlto"
                suffix="Metros"
 color="white"
          v-model="alto1"
          filled
          
        ></v-text-field>
          </v-card>
        </v-col>
     
      </v-row>

     <v-row v-if="select == 'Vision En L'||select == 'Vision En C'"
        class="mb-4"
        no-gutters
     color="black"
      >
        <v-col
          
        >
          <v-card
            class="pa-2"
            outlined
            tile
             color="black"
          >
            <v-text-field
            color="white"
   dark
     v-if="select"
     background-color="grey"
    loading
  label="Indicar Ancho B "
            auto-grow
            outlined

               :rules="numberRules"
                suffix="Metros"

          v-model="ancho2"
          filled
  
       
        ></v-text-field>
          </v-card>
        </v-col>
        
         <v-col

        >
          <v-card
            class="pa-2"
            outlined
            tile
            color="black"
          >
         <v-text-field
 dark
              v-if="select"
             background-color="grey"
 loading
  label="Indicar Alto B"
            auto-grow
            outlined
            rows="1"
            row-height="15"
      :rules="numberAlto"
                suffix="Metros"
 color="white"
          v-model="alto2"
          filled
          
        ></v-text-field>
          </v-card>
        </v-col>
     
      </v-row>
         
<v-row v-if="select == 'Vision En C'"
        class="mb-4"
        no-gutters
     color="black"
      >
        <v-col
          
        >
          <v-card
            class="pa-2"
            outlined
            tile
             color="black"
          >
            <v-text-field
            color="white"
   dark
     v-if="select"
     background-color="grey"
    loading
  label="Indicar Ancho B "
            auto-grow
            outlined

               :rules="numberRules"
                suffix="Metros"

          v-model="ancho3"
          filled
  
       
        ></v-text-field>
          </v-card>
        </v-col>
        
         <v-col

        >
          <v-card
            class="pa-2"
            outlined
            tile
            color="black"
          >
         <v-text-field
 dark
              v-if="select"
             background-color="grey"
 loading
  label="Indicar Alto B"
            auto-grow
            outlined
            rows="1"
            row-height="15"
               :rules="numberAlto"
                suffix="Metros"
 color="white"
          v-model="alto3"
          filled
          
        ></v-text-field>
          </v-card>
        </v-col>
     
      </v-row>
 

                    <v-text-field
                    dark
                color="white"
                      background-color="grey"
                      v-model="Comentario"
                   v-if="select"
                      label="Nombre de Condominio o Comentario"
             
                    ></v-text-field>
 <v-btn
             :disabled="!valid"
 v-if="m2Total>0 "
  background-color="grey"
      
                color="white"
              @click="add"
            >
             Enviar </v-btn>
                  
                 
              <br />   
              <br />   
              <br />   
              <br />   
               <br />   
              <br />   
              <br />   
                 
                  </v-form>
                </v-col>

              
              </v-row>

            </v-row>
           
          </v-col>

        </v-row>

      </v-container>
  
  
  </v-app>

</template>

<script>
// Importing Scatter and eosjs.

//import db from '@/firebase/init';

import * as firebase from "firebase";

import db from "../firebase/init.js";

import axios from "axios"



// Scatter comes without plugins, so we need to add the eosjs plugin.


// Notice that our eosjs reference isn't reactive ( in the data() method like the rest ).
// You don't want to set it onto the data of this component,
// but you CAN set it as a VUEX state property if you wanted.


export default {
  name: "Chat",
  components: {   },
   props: ["email", "foto", ],

  data() {
    return {
      
        numberRules: [
             v => !!v || 'Es requerido',
    v => v.length > 0 || 'No puede estar vacio',
  v => (v || ',').indexOf(',') < 0  || 'La coma (,) no esta permitida utilizar el punto (.) para decimales',
    v => v > 0 || 'Tiene que ser mayor a 0',
      v => v < 50 || 'Tiene que ser menor que 50'
  ],

      numberAlto: [
           v => !!v || 'Es requirido',
    v => v.length > 0 || 'No puede estar vacio',
    v => v <  2.5 || 'Supera el alto permitido, o La coma (,) no esta permitida',
  v => (v || ',').indexOf(',') < 0  || 'La coma (,) no esta permitida utilizar el punto (.) para decimales',
    v => v > 0 || 'Tiene que ser mayor a 0'
  ],
  fechalogin:null,
  nombre:"",      
dialog:false,
    cond: [],
    selections: null,
    gname:null,
    name:"",
Condos:[],
Condominio:[],
alto1:0,   
alto2:0,
alto3:0,     
ancho1:0,   
ancho2:0, 
ancho3:0,    
p:[],
feedback:"",
selectSub: null,
      
Tabla:  [],
modulo:[],   
sinRepetidosModulo:['Visión Lineal (Frontis)', 'Vision En L', 'Vision En C'],
sinRepetidosSubModulo:[],
 SubModulo:[],
     Telefono:null,
      valid: true,
      NombreEstable: null,
      Comentario: null,
      Pais: null,
time:null,
      logo: null,
      imageUrl: null,
      drawer: null,
      inset: false,
      cart: true,
      categ: false,
      platos: false,
      cuenta: false,
      establecimiento: false,
      tutoriales: false,
      texto: "Ticket",
      ico: "mdi-book-open",
      color: "blue",


      NombreEstableRules: null,
      n: [(v) => !!v || " Es requirido"],

      ComunaRules: [(v) => !!v || "La Comuna es requerido"],
          TelefonoRules: [(v) => !!v || "Telefono es requerido"],

      PaisRule: [(v) => !!v || "E-mail is required"],

      select:'Lineal (Frontis)',
        items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
      ],
      checkbox: false,
    };
  },

  created() {
let _self = this;

 

axios.get("https://sheetdb.io/api/v1/7lpky6f59efmq").then((response) => {
    _self.Condos = response.data;

     _self.Condos.map((a) => {
        _self.Condominio.push(a.Comuna);
      });
    })

 
 var docRef = db.collection("users").doc(this.email);

    docRef

      .get()
      .then(doc => {
        if (doc.exists) {
          this.fechalogin = doc.data().creacion_tiempo;
        this.nombre = doc.data().name;
 this.time = doc.data().sign_tiempo;
        
        } else {
          this.time= "no";
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });

 
    
  },

  methods: {


   add() {
      if (this.Telefono.length && this.selections.length) {
        let ref = db.collection("ventas").doc()
this.dialog  = true
        ref.set(
          { nombre:this.nombre,
            Fechadesolicitud: this.time,
            FechadeCracion:this.fechalogin,
            Telefono: this.Telefono,
            Comuna: this.selections,
            MailCliente: this.email,
            MailValora:"bejarcarlos@valoradesign.com",
            alto1:this.alto1, 
            diasdeperma:this.permanencia,  
alto2:this.alto2,
alto3:this.alto3,     
ancho1:this.ancho1,  
ancho2:this.ancho2, 
ancho3:this.ancho3, 
vano:this.select,
comentario: this.Comentario,
m2Total:this.m2Total,
m2_1:this.m2_1,
m2_2:this.m2_2,
m2_3:this.m2_3,
precio:new Intl.NumberFormat("de-DE").format(
                  this.precio
                ),
          },
          { merge: true }
        );
    
      } else this.feedback = "Ha indicado que quires gastar sólo $" + this.Life;
    },
    
    carta() {
      this.$router.push({
        name: "Chat",
        params: {
          email: this.email,
          life: this.life,
          foto: this.foto,
          name: this.name,
        },
      });
    },
    carte() {
      this.$router.push({
        name: "Categoria",
        params: {
          email: this.email,
          life: this.life,
          foto: this.foto,
          name: this.name,
        },
      });
    },

    plato() {
      this.$router.push({
        name: "Platos",
        params: {
          email: this.email,
          life: this.life,
          foto: this.foto,
          name: this.name,
        },
      });
    },

    cuent() {
      this.$router.push({
        name: "Cuenta",
        params: {
          email: this.email,
          life: this.life,
          foto: this.foto,
          name: this.name,
        },
      });
    },

    establecimien() {
      this.$router.push({
        name: "Estable",
        params: {
          email: this.email,
          life: this.life,
          foto: this.foto,
          name: this.name,
        },
      });
    },
    tutoriale() {
      this.$router.push({
        name: "Tutoriales",
        params: {
          email: this.email,
          life: this.life,
          foto: this.foto,
          name: this.name,
        },
      });
    },

   

    onUpload(e) {
      let file = e;
      console.log(firebase.storage());
      console.log(e);
      var uploadTask = firebase
        .storage()
        .ref("users/" )
        .put(file);
      uploadTask.on(
        "state_changed",

        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            this.imageUrl = downloadURL;
          });
        }
      );
    },
  },

  computed: {


precio:  function () {
  
      var m = (this.m2Total * 292000)

      return  m.toFixed(0);
    },


 m2_2: function () {
  
      var m = (this.ancho2 * this.alto2)

      

      return  m.toFixed(2);
    },



 m2_3: function () {
  
      var m = (this.ancho3 * this.alto3)

      return  m.toFixed(2);
    },

 m2_1: function () {
  
      var m = (this.ancho1 * this.alto1)

      return  m.toFixed(2);
    },


    m2Total: function () {
  
      var m = (this.ancho1 * this.alto1)+(this.ancho2 * this.alto2)+(this.ancho3 * this.alto3);

      

      return  m.toFixed(2);
    },

    
ancho: function () {
  
      var m = (this.ancho1)

      

      return  m.toFixed(2);
    },


permanencia: function () {
  
      var m = (this.fechalogin-this.time)

      

      return  m.toFixed(2);
    },

  },


watch: {
 



   select() {

this.alto1 = 0,   
this.alto2= 0,
this.alto3=0,     
this.ancho1=0,   
this.ancho2=0, 
this.ancho3=0}  
   

  },





};
</script>
