<template>
  <v-container >

      <v-flex xs12 sm6 align-center
        >
        <v-img
          class="white--text align-center"
          src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/DUO%20TITULO.png?alt=media&token=7900363e-ec95-4367-b4bd-3d54479f9ebb"
        >
        </v-img>

           <h3
            class="text-center  text-justify  Subtitle-1 font-weight-light white--text title mb-1"
          >
       
        </h3>
      </v-flex>

<v-parallax
      dark
      height="500"
      src="https://www.narvik.cl/wp-content/uploads/2015/10/roller-duo-09.jpg"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    >
      <v-row align-center align="center" justify="center">
        <v-col cols="8" class="text-center">
          <h1 class="font-weight-medium">
            {{ descripcion }}
          </h1>
          <h4 class="subheading"></h4>
        </v-col>
      </v-row>
    </v-parallax>
  <br />
    <br />

  <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

          <v-card
        color="black"
          >

             <iframe
  
          src="//fast.wistia.net/embed/iframe/a0axidcq17"
          frameborder="0"
          scrolling="no"
           
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
           :width="Width"
        :height="Width"     
        >

        </iframe>

  
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >




          <v-card
          color = "black"
            class="pa-2"
            outlined
            tile
     
          >
          <v-card>
      
      <v-tabs dark horizontal>
        <v-tab>
    
        DESCRIPCIÓN
        </v-tab>
        <v-tab>
   
       VIDEOS
        </v-tab>
        <v-tab>
    FOTOS
        </v-tab>
  
        <v-tab-item>
          <v-card      class="text-justify " flat>
            
             <v-card-title >
              Colección ROLLER DUO
              </v-card-title>
  
             <v-card-text     >Es una de nuestras colecciones más innovadoras, decorativas y vanguardistas, su tela esta compuesta por 100% poliéster, cuenta con una combinación de franjas translucidas y opacas que a medida que están suben o bajan controlan la luminosidad y la privacidad de los ambientes. <br/><br/> Poseemos 2 tipos de accionamiento:  manual y motorizado muy sencillos de usar, el manual es similar a la de nuestras otras colecciones de cortinas enrollables, ambos componentes se caracterizan por su alta resistencia y durabilidad. 
Sus telas recogen los colores de las tendencias actuales, en tonos arenas, terrosos y grises, con elegantes, finas y variadas tonalidades, texturas y gramajes. 
Ente sus muchos atributos se destacan las cenefas cuyas tonalidades combina muy bien con las telas, lo que asegura una vista más elegante y de mayor protección.<br/><br/> 
La colección Dúo consta de 2 alternativas Dúo Blackout la cual se utiliza en espacios en que se requiere control de la luz en un 90% y grados de privacidad total, especialmente recomendada para dormitorios, terrazas y salas de estar. En tanto la Dúo Traslucida se aplica para espacios de estar en donde no se requiere oscuridad. Es una cortina que definitivamente se integra perfectamente a cualquier ambiente otorgando un toque de elegancia y modernidad..    </v-card-text>
          
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="black" flat>

      <v-row>

      <br/>  <br/>      <br/>   <br/>      <br/>        
      <v-col
         v-for="(social, i) in youtubeLink"
        :key="i"
        class="d-flex child-flex"
        cols="6"
      >
    <br/> <br/>   <br/>      <br/>            
    <LazyYoutube
       
        :src="social.video"
        max-width="250x"
        aspect-ratio="16:9"
        :thumbnail-listeners="{ load: foo }"
       
    />
      </v-col>
    </v-row>

            <v-card-text>
           
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="black" flat>
         
     <v-row>
          <v-spacer></v-spacer>
          <v-col
            v-for="card in fotos"
            :key="card"
            cols="12"
            sm="6"
            md="6"
          >
            <v-card >
           
              <v-img
                :src="card.fotos"
                height="200px"
              >
               
              </v-img>
  
              
            </v-card>
          </v-col>
        </v-row>



          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
          </v-card>
        </v-col>
      </v-row>






    <v-flex xs12 sm5 align-center
        >


  
      </v-flex>

   
   
   

    
    <br />
    <br />
    
 <v-divider color="white" inset></v-divider>
   
 
  </v-container>
</template>

<script>
import db from "@/firebase/init.js";
import { LazyYoutube,  } from "vue-lazytube";

export default {
  name: "ABAP1",
    components: {  LazyYoutube, 
        },
  props: [],

  data() {
    return {

fotos:[
      { titulo:"", fotos:"https://www.narvik.cl/wp-content/uploads/2015/10/roller-duo-09.jpg"},
      { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-duo-01.jpg"},
       { titulo:"",fotos:"https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/DUO%20CAFE.jpg?alt=media&token=78447970-88af-4444-aab7-2216b859f109"},
      { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-duo-04.jpg"},
       { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/11/roller-duo-10.jpg"},
      { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-duo-05.jpg"},
],

     cards: [
    
      { title: '1', src: '',  flex: 6 ,video:"//fast.wistia.net/embed/iframe/1qxq3kwkv3",color:"black"},
      { title: '2', src: '', flex: 6 , color:"grey" },
    ],    
reveal: false,
 youtubeLink:[{video:'https://www.youtube.com/watch?v=XPdIHHPnSrM' },{video:'https://www.youtube.com/watch?v=nMSvJfFQxy4' },{video:'https://www.youtube.com/watch?v=QtwL3_60YtY' },{video:'https://www.youtube.com/watch?v=muljtwLvDVE' },{video:'https://www.youtube.com/watch?v=KtTn2uDSj4A' },{video:'https://www.youtube.com/watch?v=_NU09uSuGXY' }, ],
  
 

 tab: null,
     

      productos: "",
      Titulo: " ",
      Descripcion: "",
      Linea: "",
      Lista: "",
   
    
    };
  },
  created() {
    var docRef = db.collection("web").doc("ABAP1");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().productos;
          this.Titulo = doc.data().Titulo;
          this.Descripción = doc.data().Descripción;
          this.Lista = doc.data().Lista;
          this.Linea = doc.data().Linea;
       

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
   

  Width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350"; 
        case "sm":
          return "550";
        case "md":
          return "550";
        case 'lg':
          return "550";
        case 'xl':
          return "550";
        default:
          return '400px'
      }
    },


    height() {
      return this.width / 1.77;
    },
  },
};
</script>

