<template>
  <v-container>
 

 

      <v-flex xs12 sm6 align-center 
        >
        <v-img
          class="white--text align-center"
          src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/cortina%20TRADCIONAL.png?alt=media&token=a95ba67d-5cae-4746-bf68-974768b4680c "
        >
        </v-img>

           <h3
            class="text-center  text-justify  Subtitle-1 font-weight-light white--text title mb-1"
          >


        </h3>
      </v-flex>

   


     
<v-parallax
      dark
      height="400"
      src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/tradicional.jpeg?alt=media&token=3f4ef079-dc76-4dfc-8b5d-de0ddcbe3f83"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    >
      <v-row align-center align="center" justify="center">
        <v-col cols="8" class="text-center">
          <h1 class="font-weight-medium">
            {{ descripcion }}
          </h1>
          <h4 class="subheading"></h4>
        </v-col>
      </v-row>
    </v-parallax>
  <br />
    <br />

  <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

          <v-card
        color="black"
          >

             <iframe
  
          src="//fast.wistia.net/embed/iframe/henp49cx33"
          frameborder="0"
          scrolling="no"
           
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width"
        :height="Width"    
        >

        </iframe>

  
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >




          <v-card
          color = "black"
            class="pa-2"
            outlined
            tile
          >
          <v-card>
      
      <v-tabs dark horizontal>
        <v-tab>
    
        DESCRIPCIÓN
        </v-tab>
        <v-tab>
   
       VIDEOS
        </v-tab>
        <v-tab>
    FOTOS
        </v-tab>
  
        <v-tab-item>
          <v-card  class="text-justify " flat>
            
             <v-card-title class="text-h5">
              Colección Tradicional en Tela
              </v-card-title>
  
             <v-card-text>Es un estilo tradicional que se mantiene en la decoración actual combinada con complementos modernos de barras y rieles motorizados que combina muy bien con las tendencias actuales de ambientes minimalistas y naturales. El estilo de las cortinas tradicionales se caracteriza por caídas en telas con gramajes ligeros y tejidos naturales como la seda y el lino telas vaporosas, luminosas y ligeras en tonalidades suaves que dan mayor amplitud y con sistemas de sujeción que le dan un toque elegante y moderno, asegurando un deslizamiento sutil. Nuestro Sistema motorizado para cortinas tradicionales le dan el toque de funcionalidad Y de fácil deslizamiento el cual está compuesto por un riel de aluminio extruído con carros deslizadores guiados en su interior por una correa motorizada. La cortina es sostenida de estos carros mediante ganchos, permitiendo su movimiento de apertura o cierre suave</v-card-text>
          
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="black" flat>

      <v-row>

      <br/>  <br/>      <br/>   <br/>      <br/>        
      <v-col
         v-for="(social, i) in youtubeLink"
        :key="i"
        class="d-flex child-flex"
        cols="6"
      >
    <br/> <br/>   <br/>      <br/>            
    <LazyYoutube
       
        :src="social.video"
        max-width="250x"
        aspect-ratio="16:9"
        :thumbnail-listeners="{ load: foo }"
       
    />
      </v-col>
    </v-row>

            <v-card-text>
           
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="black" flat>
         
     <v-row>
          <v-spacer></v-spacer>
          <v-col
            v-for="card in fotos"
            :key="card"
            cols="12"
            sm="6"
            md="6"
          >
            <v-card >
           
              <v-img
                :src="card.fotos"
                height="200px"
              >
               
              </v-img>
  
              
            </v-card>
          </v-col>
        </v-row>



          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
          </v-card>
            <v-card
          class="pa-md-3 "
      color="black"
      dark
      style="max-width: 400px;"
      v-for="(r, i) in Rank"
        :key="i"
           
    >
      <v-row   >
      
      <v-card-actions class="text-caption" >
          {{r.variable}}
        <v-spacer></v-spacer>
        <span class="grey--text text--lighten-2 text-caption mr-2">
      
        </span>
        <v-rating
          v-model="r.rating"
          background-color="white"
          color="yellow accent-4"
          dense
          half-increments
          hover
          size="18"
        ></v-rating>
      </v-card-actions>
        </v-row>
    </v-card>
        </v-col>

        
      </v-row>

   <br/>      <br/>   

      <v-divider color="white" inset></v-divider>
   


 
  </v-container>
</template>

<script>
import db from "@/firebase/init.js";
import { LazyYoutube,  } from "vue-lazytube";

export default {
  name: "ABAP1",
    components: {  LazyYoutube, 
        },
  props: [],

  data() {
    return {

      Rank:[ {rating:5, variable:"Funcionalidad"},{rating:2, variable:"Elegancia"},{rating:5 , variable:"Transparencía"},{rating:5, variable:"Iluiminación"},{rating:3, variable:"Privacidad"}, {rating:1, variable:"Control Térmico"},{rating:2, variable:"Modernidad"},],
         
         
         
         
         labels: [null, null, null, null, 'great'],
 rating: 3.7,
fotos:[
      { titulo:"", fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/cortina-vertical-01.jpg"},
    
],

     cards: [
    
      { title: '1', src: '',  flex: 6 ,video:"//fast.wistia.net/embed/iframe/henp49cx33",color:"black"},
      { title: '2', src: '', flex: 6 , color:"grey" },
    ],    
reveal: false,
 youtubeLink:[{video:'https://www.youtube.com/watch?v=VwBwn0JCrFo' }, ],
  
 

 tab: null,
     

      productos: "",
      Titulo: " ",
      Descripcion: "",
      Linea: "",
      Lista: "",
   
    
    };
  },
  created() {
    var docRef = db.collection("web").doc("ABAP1");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().productos;
          this.Titulo = doc.data().Titulo;
          this.Descripción = doc.data().Descripción;
          this.Lista = doc.data().Lista;
          this.Linea = doc.data().Linea;
       

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
   

  Width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350"; 
        case "sm":
          return "550";
        case "md":
          return "550";
        case 'lg':
          return "550";
        case 'xl':
          return "550";
        default:
          return '400px'
      }
    },


    height() {
      return this.width / 1.77;
    },
  },
};
</script>

