<template>
  <v-container>
    <br />
    <br />
    <v-divider id="SAPONE"></v-divider>
    <v-layout row justify-space-around wrap align-center>
      <v-flex xs12 sm5 align-center
        ><br /><br />

        <v-img
          class="white--text align-center"
          src="https://www.valoraproyectos.com/resources/rain/CRM.png"
          height="250"
        >
        </v-img>
      </v-flex>
      <BR />
      <v-flex xs12 sm6>
         <v-container>
          <BR />
          <h3 class="text-center display-1 font-weight-light blue--text mb-1">
           {{Titulo}}
          </h3>
          <br />

          <h3
            class="text-justify  Subtitle-1 font-weight-light white--text title mb-1"
          >
         {{Descripción}}
          </h3>
        </v-container>
      </v-flex>
    </v-layout>
    <br />
    <br />
    <br />

    <v-parallax
      dark
      height="250"
      :src="productos"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    >
      <v-row align-center align="center" justify="center">
        <v-col cols="8" class="text-center">
          <h1 class="blue font-weight-medium">
        {{Linea}}
          </h1>
          <h4 class="subheading  "></h4>
        </v-col>
      </v-row>
    </v-parallax>
    <br />
 
    <v-layout row justify-space-around wrap align-start>
      <v-flex xs12 sm12 align-center>
        <v-container>
         

          <h3 class="text-center display-1 font-weight-light blue--text mb-1">
          Servicios

          </h3>




      
          <h3 class=" text-center font-weight-light black--text title mb-2">
       
          </h3>
      



        </v-container>
      </v-flex>

    
<v-flex xs12 sm6 align-center>
   <v-expansion-panels   accordion>
      <v-expansion-panel  
        v-for="(a,i) in  servicios"
        :key="i"
      >
        <v-expansion-panel-header  expand-icon="mdi-check" disable-icon-rotate inset >       
          <v-row justify="center" align="start">
 
         
         
            {{a.nombre}}
        
      <p class="text-left" >
   
      </p>
   
          
  </v-row>

     
          
            </v-expansion-panel-header>
   
        <v-expansion-panel-content class="text-center" >
          {{a.texto}} <br/>     {{a.texto1}} <br/>     {{a.texto2}}<br/>     {{a.texto3}}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

</v-flex>


    </v-layout > 


 
  </v-container>
</template>

<script>
import db from "@/firebase/init.js";
import firebase from "firebase";
export default {
  name: "ABAP1",
  components: {},
  props: [],

  data() {
    return {






servicios:[
  {nombre:"Service ",texto:"•	Service request  •	Service order",texto1:"•	Instaled base management" },
{nombre:"Marketing ",texto:"•	Segmentation management •	External list management",texto1:"•	Campaign management •	Lead management" },


{nombre:"Sales",texto:"•	Sales Planning  •	Territory Management",texto1:"•	 Account and Contacts •	 Opportunity Management •	 Pricing",texto2:"•	 Quotation and Order Management •	 Contracts" },


{nombre:"Master Data",texto:"",texto1:"",texto2:""},
{nombre:"Middleware ",texto:"",texto1:"",texto2:""},

],


      productos: "",
      Titulo: " ",
      Descripcion: "",
      Linea: "",
      Lista: "",
   
    
    };
  },
  created() {
    var docRef = db.collection("web").doc("SAPCRM3");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().productos;
          this.Titulo = doc.data().Titulo;
          this.Descripción = doc.data().Descripción;
          this.Lista = doc.data().Lista;
          this.Linea = doc.data().Linea;
       

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "230px";
        case "sm":
          return "290px";
        case "md":
          return "363px";
        case "lg":
          return "435px";
        case "xl":
          return "600px";
      }
    },

    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "290px";
        case "sm":
          return "320px";
        case "md":
          return "4200px";
        case "lg":
          return "610px";
        case "xl":
          return "650px";
      }
    },
  },
};
</script>
