<template>
  <v-container>
 

 

      <v-flex xs12 sm6 align-center 
        >
        <v-img
          class="white--text align-center"
          src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/Copia%20de%20ValoraDesign.%20Com.png?alt=media&token=363ed13c-b1a1-4092-8eb0-1a5cd7ed2634"
        >
        </v-img>

           <h3
            class="text-center  text-justify  Subtitle-1 font-weight-light white--text title mb-1"
          >


        </h3>
      </v-flex>

   


     
<v-parallax
      dark
      height="400"
      src="https://www.narvik.cl/wp-content/uploads/2015/08/roller-screen-02.jpg"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    >
      <v-row align-center align="center" justify="center">
        <v-col cols="8" class="text-center">
          <h1 class="font-weight-medium">
            {{ descripcion }}
          </h1>
          <h4 class="subheading"></h4>
        </v-col>
      </v-row>
    </v-parallax>
  <br />
    <br />

  <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

          <v-card
        color="black"
          >

             <iframe
  
          src="//fast.wistia.net/embed/iframe/1qxq3kwkv3"
          frameborder="0"
          scrolling="no"
           
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width"
        :height="Width"    
        >

        </iframe>

  
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >




          <v-card
          color = "black"
            class="pa-2"
            outlined
            tile
          >
          <v-card>
      
      <v-tabs dark horizontal>
        <v-tab>
    
        DESCRIPCIÓN
        </v-tab>
        <v-tab>
   
       VIDEOS
        </v-tab>
        <v-tab>
    FOTOS
        </v-tab>
  
        <v-tab-item>
          <v-card  class="text-justify " flat>
            
             <v-card-title class="text-h5">
              Colección Screen
              </v-card-title>
  
             <v-card-text>Es una cortina que se adapta muy bien a cualquier espacio y decoración, especialmente recomendada para espacios pequeños, dado su carácter traslucido proyecta el paisaje exterior. Incorpora 50% de poliéster y 50% de PVC, lo que la hace durable y fácil de limpiar. Disponemos de 4 tipos de gramaje 1%-3%-5% y 12% lo que permite determinar el grado de privacidad y control de luminosidad del ambiente. Disponemos de una variedad de tonos y texturas en que priman los tonos neutros blanco, arena grises y tonos contrastantes y modernos como el gris oscuro y Dark. Sus componentes y mecanismos aseguran resistencia y durabilidad.    </v-card-text>
          
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="black" flat>

      <v-row>

      <br/>  <br/>      <br/>   <br/>      <br/>        
      <v-col
         v-for="(social, i) in youtubeLink"
        :key="i"
        class="d-flex child-flex"
        cols="6"
      >
    <br/> <br/>   <br/>      <br/>            
    <LazyYoutube
       
        :src="social.video"
        max-width="250x"
        aspect-ratio="16:9"
        :thumbnail-listeners="{ load: foo }"
       
    />
      </v-col>
    </v-row>

            <v-card-text>
           
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card color="black" flat>
         
     <v-row>
          <v-spacer></v-spacer>
          <v-col
            v-for="card in fotos"
            :key="card"
            cols="12"
            sm="6"
            md="6"
          >
            <v-card >
           
              <v-img
                :src="card.fotos"
                height="200px"
              >
               
              </v-img>
  
              
            </v-card>
          </v-col>
        </v-row>



          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
          </v-card>
            <v-card
          class="pa-md-3 "
      color="black"
      dark
      style="max-width: 400px;"
      v-for="(r, i) in Rank"
        :key="i"
           
    >
      <v-row   >
      
      <v-card-actions class="text-caption" >
          {{r.variable}}
        <v-spacer></v-spacer>
        <span class="grey--text text--lighten-2 text-caption mr-2">
      
        </span>
        <v-rating
          v-model="r.rating"
          background-color="white"
          color="yellow accent-4"
          dense
          half-increments
          hover
          size="18"
        ></v-rating>
      </v-card-actions>
        </v-row>
    </v-card>
        </v-col>

        
      </v-row>

   <br/>      <br/>   

      <v-divider color="white" inset></v-divider>
   


 
  </v-container>
</template>

<script>
import db from "@/firebase/init.js";
import { LazyYoutube,  } from "vue-lazytube";

export default {
  name: "ABAP1",
    components: {  LazyYoutube, 
        },
  props: [],

  data() {
    return {

      Rank:[ {rating:5, variable:"Funcionalidad"},{rating:2, variable:"Elegancia"},{rating:5 , variable:"Transparencía"},{rating:5, variable:"Iluiminación"},{rating:3, variable:"Privacidad"}, {rating:1, variable:"Control Térmico"},{rating:2, variable:"Modernidad"},],
         
         
         
         
         labels: [null, null, null, null, 'great'],
 rating: 3.7,
fotos:[
      { titulo:"", fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-screen-01.jpg"},
      { titulo:"",fotos:"https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/ROLLER%20SCREEN%20MAR.jpg?alt=media&token=a9ade73a-fb1d-4587-a5fc-dff90a0e40dc"},
      { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-screen-04.jpg"},
       { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-screen-05.jpg"},
      { titulo:"",fotos:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-screen-06.jpg"},
],

     cards: [
    
      { title: '1', src: '',  flex: 6 ,video:"//fast.wistia.net/embed/iframe/1qxq3kwkv3",color:"black"},
      { title: '2', src: '', flex: 6 , color:"grey" },
    ],    
reveal: false,
 youtubeLink:[{video:'https://www.youtube.com/watch?v=c7EjHuMP2sI' },{video:'https://www.youtube.com/watch?v=6VOqbNsJRng' },{video:'https://www.youtube.com/watch?v=yE9Zt3Fzpt8' },{video:'https://www.youtube.com/watch?v=X5zADiWmDIQ' },{video:'https://www.youtube.com/watch?v=Mxz_5v2IIAs' },{video:'https://www.youtube.com/watch?v=-qS8BxoiZIE' }, ],
  
 

 tab: null,
     

      productos: "",
      Titulo: " ",
      Descripcion: "",
      Linea: "",
      Lista: "",
   
    
    };
  },
  created() {
    var docRef = db.collection("web").doc("ABAP1");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().productos;
          this.Titulo = doc.data().Titulo;
          this.Descripción = doc.data().Descripción;
          this.Lista = doc.data().Lista;
          this.Linea = doc.data().Linea;
       

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
   

  Width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350"; 
        case "sm":
          return "550";
        case "md":
          return "550";
        case 'lg':
          return "550";
        case 'xl':
          return "550";
        default:
          return '400px'
      }
    },


    height() {
      return this.width / 1.77;
    },
  },
};
</script>

