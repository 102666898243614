import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueScrollto from "vue-scrollto";
import LazyYoutube from "vue-lazytube";


Vue.component("LazyYoutube", LazyYoutube);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  vueScrollto,


  render: (h) => h(App),
}).$mount("#app");
