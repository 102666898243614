import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Cliente from "../views/Cliente.vue";
import Servicio from "../views/Servicio.vue";
import Termo from "../views/Nosotros/TERMO.vue";
import Vision from "../views/Nosotros/VISION.vue";
import Tauro from "../views/Nosotros/TAURO.vue";
import Trabajos from "../views/Trabajos.vue";
import Clientes from "../views/Clientes.vue";
import Deco from "../views/Deco.vue";
import Banquinas from "../views/Nosotros/Banquinas.vue";
import NuestrosClientes from "../views/Nuestrosclientes.vue";
import Boton from "../views/Botondepago.vue";
import Ok from "../views/OK.vue";
Vue.use(VueRouter);

const routes = [

  {
    path: "/deco",
    name: "deco",
    component: Deco,
  },

  {
    path: "/cierredecristal",
    name: "cierredecristal",
    component: Vision,
  },
  {
    path: "/ok",
    name: "ok",
    component: Ok,
  },


  {
    path: "/botondepagoN10728",
    name: "botondepago",
    component: Boton,
  },

  {
    path: "/cierre_plegable_termo/",
    name: "cierre_plegable_termo",
    component: Termo,
  },

  {
    path: "/cierreplegableterraza/",
    name: "cierreplegableterraza",
    component: Tauro,
  },

{
    path: "/trabajos/",
    name: "Trabajos",
    component: Trabajos,
  },

  {
    path: "/clientes/",
    name: "Clientes",
    component: Clientes,
  },


  
  {
    path: "/nuestrosclientes/",
    name: "nuestrosClientes",
    component: NuestrosClientes,
  },
  {
    path: "/banquinas/",
    name: "Banquinas",
    component: Banquinas,
  },

  {
    path: "/collection/",
    name: "Servicio",
    component: Servicio,
  },

  {
    path: "/cliente/:id",
    name: "Cliente",
    component: Cliente,
    props: true,
    beforeEnter: (to, from, next) => {
      if (to.params.email) {
        next();
      } else {
        next({ name: "Home" });
        from({ name: "Home" });
      }
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/",
    hash:"Home",
    name: "Home",
    component: Home,
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      let h = to.hash;
      return {
        selector: h,
        // , offset: { x: 0, y: 10 }
      } 
    }
  },
});

export default router;
