var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","dense":"","fixed":"","flat":"","color":"black","dark":""}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogmenu),callback:function ($$v) {_vm.dialogmenu=$$v},expression:"dialogmenu"}},[_c('v-card',{staticClass:"text-h5 lighten-2"},[_c('v-card-title',[_vm._v(" Soluciones: ")]),_c('v-divider'),_c('br'),_c('v-card-text',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Cierres de Cristal ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.cierresplegables),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.dialogmenu = false}}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        
                      name: item.name,
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('br'),_vm._v(" "),_c('br'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Persianas y Cortinas ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.CortinasRoller),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.dialogmenu = false}}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        name: 'Servicio',
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('br'),_vm._v(" "),_c('br'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Casa Inteligente ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.casainteligentes),function(item,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        name: 'Servicio',
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('br'),_vm._v(" "),_c('br'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","to":"/deco/"}},'v-btn',attrs,false),on),[_vm._v(" Decoración de Interior ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.x),function(item,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        name: 'Rain',
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('br'),_vm._v(" "),_c('br'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","to":"/Nuestrosclientes/"}},'v-btn',attrs,false),on),[_vm._v(" Testimonios de Clientes ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.x),function(item,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        name: 'nuestrosClientes',
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('br'),_vm._v(" "),_c('br'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","to":"/blog/"},on:{"click":function($event){_vm.dialogmenu = false}}},'v-btn',attrs,false),on),[_vm._v(" Blog ValoraDesign ")])]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialogmenu = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-container',[_c('v-icon',{staticClass:"hidden-sm-and-up",on:{"click":function($event){$event.stopPropagation();_vm.dialogmenu = !_vm.dialogmenu}}},[_vm._v(" mdi-menu ")]),_c('v-navigation-drawer',{attrs:{"width":"450","dark":"","color":"black","left":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-tile-action',[_c('v-spacer'),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu ")]),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')],1)],1),_c('div',{staticClass:"d-flex align-center"}),_c('v-toolbar-title'),_c('v-tabs',{staticClass:"hidden-sm-and-down",attrs:{"hide-slider":"","optional":""}},[_c('v-tab',{attrs:{"to":"/"}},[_vm._v("Inicio")]),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_vm._v("Cierre de Cristal ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.cierresplegables),function(item,index){return _c('v-list-item',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        name: item.name,
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_vm._v("Persianas y Cortinas")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.CortinasRoller),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.dialogmenu = false}}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{
                        name: 'Servicio',
                        params: { id: item.value },
                        hash: item.value,
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text))])],1)],1)}),1)],1)],1),_c('v-tab',{attrs:{"to":"/deco"}},[_vm._v("Decoración de Interior ")]),_c('v-tab',{attrs:{"to":"/Nuestrosclientes/"}},[_vm._v("Testimonios Clientes")]),_c('v-tab',{attrs:{"to":"/blog/"}},[_vm._v("Blog")]),_c('v-tab',{attrs:{"to":"/trabajos/"}},[_vm._v("Trabaja con Nosotros")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }