<template>
  <v-container fluid class="black" align="center"  > 
     
      <div id="vision"></div>
    <br />
 
  
    
    <v-flex xs11 sm12 align-center>
 
 
      <v-flex xs12 sm11 align-center 
        >
        <v-img
        width="100%" 
          class="white--text align-center"
         src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/cierre%20de%20cristal%20vision.png?alt=media&token=3ff32e81-5478-4321-a12f-2f2b9445372c"
        >
        </v-img>

        <v-card color="black" dark
       class=" text-center  white--text title mb-2"
          flat >

        <v-card-text v-if="Width>549" class="text-h5" >   
Imagina ... poder aprovechar al máximo el espacio que tienes en tu balcón.  <br /> 
Imagina.... poder disfrutar de tu balcón durante todo el año y  en todas las estaciones. <br /> 
Imagina...tener un lugar acogedor con vista panorámica donde puedas pasar tiempo con tus seres queridos. 
       
         <br />
         </v-card-text>

 <v-card-text v-if="Width<549" class="text-h7 " >   
Imagina ... poder aprovechar al máximo el espacio que tienes en tu balcón.  <br /> <br /> 
Imagina.... poder disfrutar de tu balcón durante todo el año y  en todas las estaciones. <br /> <br /> 
Imagina...tener un lugar acogedor con vista panorámica donde puedas pasar tiempo con tus seres queridos. 
       
         <br />
         </v-card-text>


        </v-card>


<div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      
      >
      
  
        <v-card  height="600"   color="black">
        
  
<iframe src="https://valoradesign.activehosted.com/f/1/" width="100%" height="700" frameborder="0"></iframe>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              text
              @click="dialog = false"
            >
             Volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

</div >

           
      </v-flex>

             <iframe

        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/pxaarsm0c9"
          frameborder="0"
          scrolling="no"
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="width2*0.97"
        :height="width2*0.45"    
        >
        </iframe>
      
    </v-flex>
  
  
  
  
  
    <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

        
        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >


        </v-col>

        
      </v-row>
  
  
  
  
  
  
  
  
  
  
  
   <h3


          
     class="text-justify   text-xs-h6           [1] Subtitle-1 font-weight-light white--text title mb-1"
          >
 


 
 

   
  </h3>
 


      <v-card
          color = "#6c6c6c"
            class="pa-2"
            outlined
            dark
            tile
          >
          <v-card     color = "grey darken-2" >
      
      <v-tabs dark horizontal>
        <v-tab>
     DESCRIPCIÓN
        </v-tab>
        <v-tab>
    
      <div class="text-center">
    <v-progress-circular
        :size="15"
        color="amber"
        indeterminate
      ></v-progress-circular>
      </div>
    TESTIMONIOS
   
  
        </v-tab>
        <v-tab>
    COTIZAR
        </v-tab>
  
        <v-tab-item>
          <v-card  color="#6c6c6c" 
       class=" text-center font-weight-light white--text title "
          flat>
            


  <BR/><BR/>
 
  <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

          <v-card
        color="#6c6c6c"
            outlined
            dark
        
            tile
          >

     <iframe
  
        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/wy4tj4tkan"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width"
        :height="Width"    
        >

        </iframe>

  
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
           sm="12"
            xs="12"
           
        >


          <v-card
          color="#6c6c6c"
         class="text-justify mx-auto" 
        dark
            outlined
            tile
          >
        <v-card-title class="text-h5">
              VISION PLUS - ECO VISION
              </v-card-title>
  
             <v-card-text>  
 Nuestro Sistema de Cierre Plegable de Cristal -Visión – está diseñado para transformar su balcón en el espacio soñado, con mayor versatilidad, confort y seguridad, protegiendo del viento, lluvia, polvo y frío. <BR/>  <BR/>	Su belleza estética complementa la decoración, añadiendo valor a su propiedad.
 <p class="hidden-sm-and-up" >Visión es un Innovador sistema de cerramientos de cristal plegable y apilable  para balcones, de procedencia europea,  que asegura máxima seguridad y estabilidad al contemplar en su estructura una carga compartida entre  su base superior e inferior.  <BR/><BR/> </P> 

<BR/>


    <v-dialog
        v-model="dialogvi"
        width="500"
        max-height="500"
        scrollable
    
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#6c6c6c"
            dark
            v-bind="attrs"
            v-on="on"
          >
            ...Seguir leyendo
          </v-btn>
        </template>
  
        <v-card
        dark
         color="#6c6c6c"
          width="500"
        max-height="500"
        >
          <v-card-title class="text-h5 grey lighten-1">
    VISION 
          </v-card-title>
  
          <v-card-text>
              <BR/><BR/>
      El Sistema utiliza cristales de seguridad templado de 8 mm, con una elegante perfilería de aluminio anodizado de color blanco, que evita su oxidación.  Su deslizamiento se produce a través de una puerta  abatible  ubicada en uno o dos extremos laterales de los vanos para permitir la salida y apilamiento de  hojas que corren horizontalmente. 
 
<BR/> <BR/> 

<p class="hidden-sm-and-down" >Visión es un Innovador sistema de cerramientos de cristal plegable y apilable  para balcones, de procedencia europea,  que asegura máxima seguridad y estabilidad al contemplar en su estructura una carga compartida entre  su base superior e inferior.  <BR/><BR/> </P> 


   <v-img
                      :width="Width*1.23*0.8"
        :height="Width*0.70*0.79"     
          class="white--text align-center"
         src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/eco%20vision%20visionplus.png?alt=media&token=eefb5a8a-b9bf-478c-b4f9-31c4641c79a9"
        >
        </v-img>
<BR/>

<v-card
        >
<v-simple-table
                      fixed-header >
      <template v-slot:default>
        <thead>
          <tr>
            <tH class="font-weight-bold">COMPONENTES</tH>
            <th class="text-left">
              ECO VISION
            </th>
              <th class="text-left">
              VISION PLUS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in compo"
            :key="item.name"
          >
            <td class="font-weight-bold">{{ item.componente }}
    



            </td>



            <td>{{ item.eco }}


     
   




            </td>
            <td>{{ item.plus }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-card>




<BR/> Visión es el  sistema de mayor estanqueidad  del mercado, al considerar  perfiles,  felpas  y un prolijo sellado estructural  que lo hace  impenetrable al viento, al frio y a la lluvia.
<BR/>



<BR/>

 <v-card
        color="#6c6c6c"
            outlined
            dark
            tile
          >
 <BR/>   
     <iframe
  
        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/c4brmsyzis"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
          :width="Width*1.23*0.65"
        :height="Width*0.70*0.68"    
        >

        </iframe>

  
          </v-card>


 </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>

            <v-btn
              color="White"
              text
              @click="dialog = true"
            >
              Cotizar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="White"
              text
              @click="dialogvi = false"
            >
              volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>








   
  
             </v-card-text>
          
  
             </v-card>
        </v-col>
      </v-row>


 <v-row no-gutters>
        <v-col
          cols="11"
          sm="11"
          md="6"
         xs="11"
          
        >

 <v-card

          color="#6c6c6c"
          class="text-justify mx-auto" 
        dark
              :width="Width"
            outlined
            tile
          >
        <v-card-title class="text-h5">
           <BR/>  
           LATERALES HERMÉTICOS
              </v-card-title>
  
             <v-card-text>
 
    Único sistema de acristalamiento con finas terminaciones en laterales muro- cristal que se complementan con un fino perfil estructural y felpa, asegurando absoluta estanqueidad y terminaciones de sellado de mayor estética.
  

  <BR/>  
  


             </v-card-text>
          
  
             </v-card>



        
        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >

  <v-card
        color="#6c6c6c"
            outlined
            dark
            tile
          >
 <BR/>   
     <iframe
  
        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/8w8urpj0of"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
          :width="Width*1.23*0.8"
        :height="Width*0.70*0.79"    
        >

        </iframe>

  
          </v-card>
         
        </v-col>
      </v-row>






  <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

          <v-card
        color="#6c6c6c"
            outlined
            dark
            tile
          class="hidden-sm-and-down"
          >

     <iframe
  
        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/z77s71sgc0"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width*1.23*0.8"
        :height="Width*0.70*0.79"    
        >

        </iframe>

  
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >

<BR/>  
          <v-card
          color="#6c6c6c"
         class="text-justify mx-auto" 
        dark
            outlined
            tile
          >
        <v-card-title class="text-h5">
           BURLETES INVISIBLES
              </v-card-title>
  
             <v-card-text>
 
       Es el único sistema con burletes de acrílicos entre paneles con forma de H de un  tono translúcido   que aseguran  una visual limpia, estética, de mayor duración y  obteniéndose un mejor aislamiento térmico y acústico.
       
 

  <BR/>  <BR/>
    <v-card
        color="#6c6c6c"
            outlined
            dark
            tile
          class="hidden-sm-and-up"
          >

     <iframe
  
        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/z77s71sgc0"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width*1.23*0.8"
        :height="Width*0.70*0.79"    
        >

        </iframe>

  
          </v-card>


      <v-dialog
        v-model="dialoginv"
        width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#6c6c6c"
            dark
            v-bind="attrs"
            v-on="on"
          >
            ...ver video comparativo
          </v-btn>
        </template>
  
        <v-card
        dark
         color="#6c6c6c"
        >
          <v-card-title class="text-h5 grey lighten-1">
             Video comparativo
          </v-card-title>
  
          <v-card-text>
              <BR/><BR/>
   
  
 <iframe
  
    
          src="//fast.wistia.net/embed/iframe/ujwlybme45"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width*1.23*0.75"
        :height="Width*0.70*0.75"    
        >

        </iframe>
   
    </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>

            <v-btn
              color="White"
              text
              @click="dialog = true"
            >
              Cotizar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="White"
              text
              @click="dialoginv = false"
            >
              Volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  <BR/>

             </v-card-text>
          
  
             </v-card>
        </v-col>
      </v-row>



<v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
         xs="12"
          
        >

        <v-card
          color="#6c6c6c"
          class="text-justify " 
        dark
            outlined
            tile
          >
        <v-card-title class="text-h5">
              ACOPLE PERFECTO
              </v-card-title>
  
             <v-card-text>
 
       Los paneles de cristal de Visión se desplazan a través de perfiles de aluminio anodizado el cual utiliza mecanimos de unión macho/hembra para un acople perfecto para impedir el paso del viento y lluvia. El sistema de ruedas de poliuretano facilita el deslizamiento y el riesgo de afección  en climas adversos, por lo que el mantenimiento es mínimo.



<BR/>

    <v-dialog
        v-model="dialogvii"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#6c6c6c"
            dark
            v-bind="attrs"
            v-on="on"
          >
            ...Seguir leyendo
          </v-btn>
        </template>
  
        <v-card
        dark
         color="#6c6c6c"
        >
          <v-card-title class="text-h5 grey lighten-1">
             Opcionales de Visión:
          </v-card-title>
  
          <v-card-text>
              <BR/><BR/>
   
  
    
•	Instalación de Láminas de Control Solar para reducir  el calor, eliminar el reflejo y evitar las decoloración de sus muebles.
  <BR/><BR/> 
•	Instalación de  cortinas o persianas para el control solar, la privacidad  y evitar las decoloración de sus muebles.
  <BR/><BR/> 
•	Instalación de Cerradura Mecánica o Digital para cristal, es una solución perfecta para garantizar la seguridad de su terraza frente a intrusiones exteriores o prevenir accidentes domésticos.
  
   
    </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>

            <v-btn
              color="White"
              text
              @click="dialog = true"
            >
              Cotizar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="White"
              text
              @click="dialogvii = false"
            >
              Volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>




    

  <BR/><BR/>

             </v-card-text>
          
  
             </v-card>




        </v-col>
        <v-col
          cols="12"
          md="6"
           sm="12"
            xs="12"
           
        >


    <v-card
        color="#6c6c6c"
            outlined
            dark
            tile
          >

     <iframe
  
        style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/cnfxk9drkx"
          frameborder="0"
          scrolling="no"
            name="wistia_embed"
          allowfullscreen
             color="grey darken-2"
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="Width*1.23*0.8"
        :height="Width*0.70*0.8"    
        >

        </iframe>

  
          </v-card>





        
        </v-col>
      </v-row>

            


          
          </v-card>
        </v-tab-item>
        <v-tab-item>

      <v-card class="grey darken-2"  >
      <v-layout row justify-space-around wrap align-start>
        <v-flex xs12 sm12 align-center>
          <v-container>
            <br />
           
            <div></div>
            <h3 class="text-center display-1 font-weight-light blue--text mb-1">
             <cierre/>
            </h3>

            <br />

          
            
            <br />

                 <h3 class="text-center display-1 font-weight-light white--text mb-1">
             
  <v-btn
        class="mx-2"
        fab
        dark
        large
        color="pink"

        href="https://www.instagram.com/valoradesign/"
        
      >
        <v-icon x-large dark>
          mdi-instagram
        </v-icon>
     </v-btn>
  
     Más Info en Instagram 
            </h3>

            <br /> <br />
          </v-container>
        </v-flex>

   
      </v-layout>
    </v-card>


 





  

        </v-tab-item>
        <v-tab-item>
          <v-card color="grey darken-2"  flat>
     <iframe src="https://valoradesign.activehosted.com/f/1/" width="100%" height="700" frameborder="0"></iframe>



          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
          </v-card>

  <BR/><BR/>


   <v-flex xs11 sm12 align-center>
 

             <iframe
         
      style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/z6ec4espov"
          frameborder="0"
          scrolling="no"
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="width2*0.95"
  :height="width2*0.45"    
        >
        </iframe>
      
    </v-flex>
  


 <v-card color="black"
       class=" text-center font-weight-light white--text title mb-2"
          flat
        >
          <br />  <br />
Usted podrá transformar su terraza en el espacio soñado, <br/> con mayor versatilidad, confort y seguridad, protegiéndose del viento, lluvia, polvo y frio. <br/>Su belleza estética complementa la decoración, añadiendo valor a su propiedad. <br/>
       

  <br />


        </v-card>


   <v-flex xs11 sm12 align-center>
 


             <iframe
         
     
           style="pointer-events:none;"  
          src="//fast.wistia.net/embed/iframe/atufhyoxv8"
          frameborder="0"
          scrolling="no"
          name="wistia_embed"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        :width="width2"
  :height="width2*0.45"    
        >
        </iframe>
      
      
    </v-flex>


 
      
   <v-card color="black"
       class=" text-center font-weight-light white--text title mb-2"
          flat
        >
Ventajas del vidrio templado

La principal característica del cristal templado es su resistencia y la densidad de sus bordes que impide la filtración de humedad frente a condiciones climáticas adversas. 
Un cristal templado de 8mm de grosor puede resistir el choque de una bola de acero de 500 gramos desde una altura de 2 Mts  y gracias a su densidad resistir cambios de temperatura de hasta 300 cc. Gracias a éstas características el cristal  templado tiene primacía de uso  en los proyectos constructivos en diversas áreas susceptibles al impacto humano.
       


        </v-card>
  


<div class="text-center ma-2">
      <v-btn
      color="yelow"
      @click="dialog=true"
      >
     Cotizar
      </v-btn>
      <v-snackbar
        v-model="snackbar"
        dark
       top
        right
        timeout="-1"
      >
Obtén una Cotización VISION
        <template v-slot:action="{ attrs }">
          <v-btn
            color="orange"
         
            left
            v-bind="attrs"
            @click="dialog=true"
          >
           Aquí
          </v-btn>
        </template>
      </v-snackbar>
    </div>
   

 
   
    <br />
  
    <br />

    
    <div id="sectores"></div>
    <br />



 


 










  


 
    
  </v-container>
</template>

<script>
import db from "@/firebase/init.js";
import cierre from "@/components/Cierre.vue";
import firebase from "firebase";

export default {
  name: "Rain",
  components: {cierre},
  props: [],

  data() {
    return {
  compo: [
        {
          componente: 'Perfil Anodizado 3 mm ',
          eco: "Si ",
          plus: "Si con Acabado Plus",
          link:"https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/cierre%20de%20cristal%20vision.png?alt=media&token=3ff32e81-5478-4321-a12f-2f2b9445372c"
        },
        {
          componente: 'Cristal ',
          eco: "Vidrio Laminado 8mm ",
          plus: "Cristal Templado 8mm",
        },
        {
     componente: 'Unión Lateral Cristal - Muro ',
          eco: "Pletina en L 30mm - 12mm",
          plus: "Perfil en L 55mm - 32mm",
        },
        {
           componente: 'Burlete entre Cristales ',
          eco: "Burlete Silla Plastico",
          plus: "Burlete H Acrilico",
        },
        {
          componente: 'Tapas Perfil Marco ',
          eco: "Distancia entre perfil 17mm (5mm entre Cristal)",
          plus: "Distancia entre perfil 12mm (4mm entre Cristal)",
        },
        
        
      ],
      dialoginv: false,
 dialogvi: false,
  dialogvii: false,
  tab1: null,
      tipos: [
        { tab: 'Cristal 8mm', content: 'Tab 1 Content', foto:"https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/8mm.png?alt=media&token=6e8ab43a-9359-452c-bbfc-5b8692dedd3e" },
        { tab: 'Cristal 10mm', content: 'Tab 2 Content' },
        { tab: 'TermoPanel 20mm', content: 'Tab 3 Content' },
       
      ],



        snackbar: false,
     timeout: 10000,

        tab: null,
      diferenciadores: [
       {nombre:"Equipo Certificado",texto:"Contamos con Certificaciones SAP de los servicios que ofrecemos"}, 
        {nombre:"Experiencia",texto:"Conocimiento y experiencia en áreas funcionales y técnicas."},
          {nombre:"Flexibilidad",texto:"Flexibilidad y adaptación a las metodologías utilizadas por nuestros clientes."},
                {nombre:"Relaciones",texto:"Buscamos la creación de relaciones a largo plazo con nuestros clientes."},
       
       
      ],
      text: "Obten tu Cotización en tiempo real.",

 capacidades: [

   { item: "MODELAMIENTO DE PROCESOS DE NEGOCIO ",Titulo: "MEJORA CONTINUA ",Descripción: "Apoyado de nuestra capacidad de entender de forma rápida y acertada los procesos de negocios, podemos modelar e implementar soluciones que satisfagan las expectativas de nuestros clientes.", p: "", a: 5 },
       
       {
          item: "OPTIMIZACIÓN DE PROCESOS TÉCNICOS",
         Titulo: "ASESORÍAS Y ASEGURAMIENTO DE CALIDAD",
         Descripción: "Contamos con un área técnica especializada en verificación de performance, mejoramiento y optimización de ampliaciones al sistema.",
          p: "",
          a: "si",
        },

        
        { item: "AUTOMATIZACIÓN DE PROCESOS",Titulo: "MESA DE AYUDA Y SOPORTE",Descripción: "Apoyado de nuestra capacidad de funcional y técnica buscamos automatizar procesos complejos de nuestros clientes ", p: "", a: 2 },
        {
          item: "INNOVACIÓN Y DESARROLLO",
         Titulo: "SOFTWARE FACTORY",
         Descripción: "Contamos con un área de innovación y desarrollo que constantemente está enfocándose en la investigación de nuevas tecnologías y como usarlas para solucionar problemas de nuestros clientes.",
          p: "",
          a: "si",
        },
       
       {
          item: "REINGENIERÍA DE PROCESOS ",
         Titulo: "CAPACITACIÓN",
         Descripción: "Apoyado de nuestra capacidad funcional y el conocimiento de diferentes industrias buscamos y simplificar y los procesos complejos de nuestros clientes ",
          p: "",
          a: "si",
        },
           
               { item: "EXPERIENCIA",Titulo: "PROYECTOS DE IMPLEMENTACIÓN", Descripción: "Apoyado de nuestra experiencia y conocimiento en proyectos a nivel nacional e internacional buscamos entregar la mejor experiencia y satisfacción al cliente en la implementación de soluciones SAP que ayuden a mejorar su negocio.", p: "", a: 50 },

             
       
      ],
 dialog:false,
  servicios: [

     {
          item: "ASESORÍAS Y ASEGURAMIENTO DE CALIDAD",
         Titulo: "ASESORÍAS Y ASEGURAMIENTO DE CALIDAD",
         Descripción: "Nuestros servicios buscan apoyar las implementaciones a empresas que no tienen un profundo conocimiento para lograr una exitosa implementación entregando nuestros servicios de levantamiento de información y apoyo en la construcción del Business Blue Print, apoyo en las pruebas unitarias/Integrales, revisión y construcción de especificaciones funcionales, revisión y construcción de especificaciones técnicas, análisis y posibles soluciones a requerimientos fuera de alcance y su construcción.",
          p: "",
          a: "si",
        },
        { item: "PROYECTOS DE IMPLEMENTACIÓN",Titulo: "PROYECTOS DE IMPLEMENTACIÓN", Descripción: "Apoyado de nuestra experiencia y conocimiento y de las metodologías de proyectos (ASAP, SAP Active, Agile) buscamos entregar la mejor experiencia en la implementación de proyectos para apoyar el crecimiento de su empresa", p: "mdi-worker", a: 50 },

        { item: "MESA DE AYUDA Y SOPORTE",Titulo: "MESA DE AYUDA Y SOPORTE",Descripción: "Mesa de ayuda y soporte para la solución de incidentes a fin de que su negocio continúe en operando, utilizando un SLA definido dependiendo del tipo de plan o paquete que sea gestionado.", p: "", a: 2 },
           {
          item: "SOFTWARE FACTORY",
         Titulo: "SOFTWARE FACTORY",
         Descripción: "Fábrica de desarrollo que permite gestionar los desarrollo con nuestro equipo especialistas dependiendo del tipo de plan o paquete que sea gestionado.",
          p: "",
          a: "si",
        },
                { item: "MEJORA CONTINUA ",Titulo: "MEJORA CONTINUA ",Descripción: "Apoyado de nuestra experiencia y conocimiento ayudamos a implementar soluciones innovadoras para mejorar los procesos de su empresa integrando diferentes productos SAP y No SAP.", p: "", a: 5 },
 
        {
          item: "CAPACITACIÓN ",
         Titulo: "CAPACITACIÓN",
         Descripción: "Capacitaciones, cursos, e inducciones de las soluciones que ofrece nuestra empresa para que se empodere de su sistema, y vea las funcionalidades que entrega cada producto, y las futuras funcionalidades",
          p: "",
          a: "si",
        },
        
            
       
      ],

      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      id: this.$route.params.id,
      fotosalud: "",
      textosalud: "",
      offsetTop: 0,
  
      e1: "Consultoria",
      fotoretail: "",
      manzana: "",
      mens: "",
      retail: "",
      produccion: "",
      productos: "",
      areas: "",
      funcion: "",
      foto2: "",
      foto3: "",
      descripcion: "",
      titulo: "",
      mensaje: "",
    };
  },
  mounted() {
    scrollTo(this.$route.params.id, 500000);
      setTimeout(() => (this.snackbar= true), 6000);
  },
  created() {
    var docRef = db.collection("web").doc("Nosotros");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().equipo;
          this.foto2 = doc.data().AFOTOC3;
          this.foto3 = doc.data().AFOTOBN2;
          this.fotoretail = doc.data().retail;
          this.fotoproduccion = doc.data().producción;
          this.manzana = doc.data().manzana;
          this.fotosalud = doc.data().salud;

          this.titulo = doc.data().BN1;
          this.mensaje = doc.data().BN2;
          this.mens = doc.data().EF2;
          
          this.textosalud = doc.data().TEXTOSALUD;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },


   beforecreated() {  

  setTimeout(() => (this.snackbar= true), 6000);

     },



  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },

 signingoogle() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((cred) => {
          let ref = db.collection("users").doc(cred.user.email);
          this.email = cred.user.email;
          ref.set(
            {name :cred.user.displayName,
              email: cred.user.email,
              user_id: cred.user.uid,
              creacion_tiempo: cred.user.metadata.creationTime,
              sign_tiempo: cred.user.metadata.lastSignInTime,
              proIndex: [],
            },
            { merge: true }
          );
        })

        .then(() => {

 this.dialog = false;
 

          this.$router.push({
            name: "Cliente",
            params: {


              email: this.email,
              life: this.life,
              foto: this.foto,
              name: this.firstName + this.lastName,
            },
          });
        });
    },


    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },

  watch: {
    $route() {
      this.id = this.$route.params.id;
    },


dialogg(al) {
    if (!al) return;

    setTimeout(() => (this.dialogg = false), 6000);
  },



    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  computed: {
    

Width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350"; 
        case "sm":
          return "550";
        case "md":
          return "550";
        case 'lg':
          return "550";
        case 'xl':
          return "550";
        default:
          return '400px'
      }
    },


    height() {
      return this.width / 1.77;
    },

   width2() {
      return this.$vuetify.breakpoint.width ;
    },

  },
};
</script>
