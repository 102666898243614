<template>

<v-card
      class="mx-auto"
      width="600"
      outlined
    >



 <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
            width="100"
              v-model="ancho"
                outlined
           :rules="numberRules"
              label="Ingresar ancho del vano"
              required
                 dense
               suffix="Metros"
            ></v-text-field>
          </v-col>
  </v-row>
     <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="alto"
              :rules="numberAlto"
              outlined
   suffix="Metros"
           dense
              label="Ingresar el alto de la baranda"
              required
            ></v-text-field>
          </v-col>
    </v-row>
    <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
             outlined
              v-model="cantidad"
              :rules="emailRules"
              label="Ingresar la cantidad de vanos (Ej. Cantidad departamentos)"
              required
                 dense
              
            ></v-text-field>
          </v-col>

<v-col cols="12">
            <v-autocomplete
              v-model="value"
              :items="items"
              dense
              outlined
              label="Seleciona el tipo de Cristal"
            ></v-autocomplete>
          </v-col>
            
        </v-row>

          <v-row>

     <v-col
            cols="12"
            md="2"
          >
            <v-text-field
            disabled 
              v-if="ancho"
             outlined
              v-model="m2_2"
              :rules="emailRules"
              label="m2"
              required
              
            ></v-text-field>
          </v-col>

           <v-col
            cols="12"
            md="2"
          >
            <v-text-field
            disabled 
                v-if="ancho"
             outlined
              v-model="ml"
              :rules="emailRules"
              label="ml"
              required
              
            ></v-text-field>
          </v-col>
   </v-row>

               <v-row>

     <v-col
            cols="12"
            md="7"
          >
            <v-text-field
            v-if="ancho"
             outlined
              v-model="nbases"
              :rules="emailRules"
              label="Cantidad de bases de 15 cm"
              required
                suffix="(Unidades)"
            ></v-text-field>

          </v-col>

           <v-col
            cols="12"
            md="5"
          >
 
              <v-icon
 small
        color="green darken-2"
      >
       mdi-share-all-outline
      </v-icon>
      

            <v-text-field
            disabled 
          v-if="ancho"
              v-model="bases"
              :rules="emailRules"
              label=" La cantidad de bases sugeridas son:"
           suffix="Unidades (U)"
              
            ></v-text-field>
          </v-col>
   </v-row>



<v-row>

     <v-col
            cols="12"
            md="4"
          >
            <v-text-field
       
             disabled 
           v-if="nbases"
             outlined
              v-model="distanciam"
              :rules="emailRules"
              label="Distancias entre bases  "
              required
              suffix="cm"
            ></v-text-field>
          </v-col>

           <v-col
            cols="12"
            md="8"
          >

              <v-icon
 small
        color="green darken-2"
      >
       mdi-share-all-outline
      </v-icon>
      

            <v-text-field
            disabled 
         v-if="nbases"
              v-model="cristal"
              :rules="emailRules"
              label="La distancia sugerida de acuerdo al alto del cristal es: "
          suffix="Debes aumentar las bases"
              
            ></v-text-field>
          </v-col>
   </v-row>


            <v-col
            cols="12"
            md="2"
          >
            <v-text-field

               v-if="distanciam>0"
             outlined
              v-model="cantidad"
              :rules="emailRules"
              label="Precio "
              required
              
            ></v-text-field>
          </v-col>
       







      </v-container>
    </v-form>
      
    </v-card>


</template>

<script>


import db from "@/firebase/init.js";
import axios from "axios";

export default {
  name: "Ecommerce",
  components: {},
  props: [],

  data() {
    return {

  items: [],
    p: [],
    value: null,
      numberRules: [
             v => !!v || 'Es requerido',
    v => v.length > 0 || 'No puede estar vacio',
  v => (v || ',').indexOf(',') < 0  || 'La coma (,) no esta permitida utilizar el punto (.) para decimales',
    v => v > 0 || 'Tiene que ser mayor a 0',
      v => v < 50 || 'Tiene que ser menor que 50'
  ],

      numberAlto: [
           v => !!v || 'Es requirido',
    v => v.length > 0 || 'No puede estar vacio',
    v => v <  2.5 || 'Supera el alto permitido, o La coma (,) no esta permitida',
  v => (v || ',').indexOf(',') < 0  || 'La coma (,) no esta permitida utilizar el punto (.) para decimales',
    v => v > 0 || 'Tiene que ser mayor a 0'
  ],


  valid: false,
    ancho: null,
      cristal: null,
      ancho2: null,
    alto: null,
    cantidad: 1,
    anchobanquinas:null,
       anchoespacios:null,
       anchoreal:null,
    nbases:null
   

      
    };
  },


   

  created() {

  let _self = this;
 //valordesign bejar carlos
    axios.get("https://sheetdb.io/api/v1/6akyprvgylf0k").then((response) => {
    _self.p = response.data;

this.items =[];
    this.p.map((a) => {
      this.items.push(a.Cristal);
    });

    });

    var docRef = db.collection("web").doc("Servicios Sap");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().AF1;
          this.foto2 = doc.data().AF0;
          this.titulo = doc.data().BN1;
          this.mensaje = doc.data().CM0;
          this.descripcion = doc.data().DD1;
          this.funcion = doc.data().EF0;
          this.funciones = doc.data().EF1;
          this.areas = doc.data().FB1;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });

      
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

 
  },

  computed: {




distanciam: function () {
  
  var m = ((this.ancho - (this.nbases* 0.15 ))/ (this.nbases-1))*100

     
      return  m.toFixed();
    },




bases: function () {
  
      var m = (this.ml/0.15 * 0.35 )

     

      return  m.toFixed();
     
    },




 ml: function () {
  
      var m = (this.ancho  * this.cantidad )

      

      return  m.toFixed(2);
    },

 m2_2: function () {
  
      var m = (this.ancho * this.alto * this.cantidad )

      

      return  m.toFixed(2);
    },

   
  },


watch: {

value() {
   

      this.cristal = [];
      this.p.map((a) => {
        if (a.Cristal == this.value && a.Altura == this.ancho2 ) {
          this.cristal = a.ancho;
        }
      });
    },



   


alto( al) {
   
if ( al < 0.61  ) {
return this.ancho2 = "0-599"
} else
   if ( al> 0.6 && al < 0.91) {
return this.ancho2 = "600-899"}

else
   if ( al> 0.91 && al < 1.1) {
return this.ancho2 = "900-1099"}

else
   if ( al> 1.1 && al < 1.21) {
return this.ancho2 = "1100-1200"}


else
 
return this.ancho2 = "1100-1200"


    },





$route() {
      this.id = this.$route.params.id;
    },


dialogg(al) {
    if (!al) return;

    setTimeout(() => (this.dialogg = false), 6000);
  },



    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },

};
</script>
