import firebase from "firebase/app";
import firestore from "firebase/firestore";
import 'firebase/storage';


var firebaseConfig = {
  apiKey: "AIzaSyBi9HLZHOBWwtsj-8f_b7ILmKvETc-YMdE",
    authDomain: "valoradesign-542be.firebaseapp.com",
    projectId: "valoradesign-542be",
    storageBucket: "valoradesign-542be.appspot.com",
    messagingSenderId: "240441857138",
    appId: "1:240441857138:web:d85eff2e007c6467a25eeb",
    measurementId: "G-7VSFX21VJP"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore();
