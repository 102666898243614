<template>
  <v-container >


    <p align="center">
<router-link to="/">

            <v-img
              alt="Rain"
              class="shrink mr-2"
              contain
              src="https://firebasestorage.googleapis.com/v0/b/valoradesign-542be.appspot.com/o/valoradesignlogo.png?alt=media&token=5daa9ca6-db00-4363-8a74-33813532fcf0"
              transition="scale-transition"
           :width="width*0.6"
            />
</router-link>
   <v-flex xs12 >
        <h1 class="text-center font-weight-thin white--text mb-1    
        
        text-h5            [1]
        text-md-h4         [2]
        text-lg-h4         [2]
        text-xl-h4         [2] ">
     ¡Haz que tu Hogar Hable de Ti!


             <br />
Atrévete a innovar!
        </h1>

 

        <br />
        <br />
      </v-flex>



</p>

 <h3 class="text-center display-1 font-weight-light blue--text mb-1">
            
            <h3 class="text-center display-1 font-weight-light white--text mb-1">
       <br/>  <br/>      <br/>       
  <v-btn
        class="mx-2"
        fab
        dark
        large
        color="pink"

        href="https://www.instagram.com/valoradesign/"
        
      >
        <v-icon x-large dark>
          mdi-instagram
        </v-icon>
     </v-btn>
  
     Más Info en Instagram 
            </h3>
            <br/>  <br/>      <br/> 
         
             <cierre/>
            </h3>
  

  </v-container>
</template>

<script>
import ANA from "@/views/Servicios/ANA.vue";
import ADONS from "@/views/Servicios/ADONS.vue";

import SOLUTIONMANAGER2 from "@/views/Servicios/SOLUTIONMANAGER2.vue";
import SAPCRM3 from "@/views/Servicios/SAPCRM3.vue";
import SAPS4HANA4 from "@/views/Servicios/SAPS4HANA4.vue";
import SAPCXC4HANA5 from "@/views/Servicios/SAPCXC4HANA5.vue";
import SAPFIORI6 from "@/views/Servicios/SAPFIORI6.vue";
import SAPCLUDPLATAFORMA8 from "@/views/Servicios/SAPCLUDPLATAFORMA8.vue";
import cierre from "@/components/Cierre.vue";

import db from "@/firebase/init.js";
import firebase from "firebase";
export default {
  name: "Ecommerce",
  components: {
    
      cierre,
    
    ANA,ADONS,SOLUTIONMANAGER2,SAPCRM3,SAPS4HANA4,SAPCXC4HANA5,SAPFIORI6,SAPCLUDPLATAFORMA8},
  props: [],

  data() {
    return {
      productos: "",
      areas: "",
      funcion: "",
      foto2: "",
      descripcion: "",
      titulo: "",
      mensaje: "",
      desserts: [
        { item: "Cantidad de productos", name: 50, calories: 50, p: 50, a: 50 },

        { item: "Cantidad de Fotos* ", name: 5, calories: 5, p: 5, a: 5 },
        { item: "Cantidad de Videos*", name: 2, calories: 2, p: 2, a: 2 },
        {
          item: "Visualización Multidispositivo",
          name: "si",
          calories: "si",
          p: "si",
          a: "si",
        },
        {
          item: "Conexión segura SSL y HTTPS",
          item: "Conexión segura SSL y HTTPS",
          name: "si",
          calories: "si",
          p: "si",
          a: "si",
        },

        {
          item: "Carro de compras e Integración de medios de pago",
          name: "",
          calories: "si",
          p: "si",
          a: "si",
        },

        {
          item: "Comunidad de clientes Condominios",
          name: "",
          calories: "",
          p: "si",
          a: " Gratis MarketPlace Shopery ",
        },

        {
          item: "Sistema de Fideliazación QR",
          name: "",
          calories: "",
          p: "",
          a: " si",
        },
        {
          item: "Sistema de Agendamiento",
          name: "",
          calories: "",
          p: "",
          a: " si",
        },
        {
          item: "Mensajes de notificación ",
          calories: "",
          p: "",
          a: " si",
        },
        {
          item: "Sistema de promocion de Ventas ",
          calories: "",
          p: "",
          a: " si",
        },

        { item: "* por cada producto", name: "", calories: "", p: "", a: "" },
      ],
    };
  },
  created() {
    var docRef = db.collection("web").doc("Servicios Sap");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().AF1;
          this.foto2 = doc.data().AF0;
          this.titulo = doc.data().BN1;
          this.mensaje = doc.data().CM0;
          this.descripcion = doc.data().DD1;
          this.funcion = doc.data().EF0;
          this.funciones = doc.data().EF1;
          this.areas = doc.data().FB1;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "230px";
        case "sm":
          return "290px";
        case "md":
          return "363px";
        case "lg":
          return "435px";
        case "xl":
          return "600px";
      }
    },

    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "290px";
        case "sm":
          return "320px";
        case "md":
          return "4200px";
        case "lg":
          return "610px";
        case "xl":
          return "650px";
      }
    },
  },
};
</script>
