<template>
  


   
   
   <v-expansion-panels   accordion>
      <v-expansion-panel  
 
      >
        <v-expansion-panel-header  color="grey" expand-icon="mdi-check" disable-icon-rotate inset >       
          <v-row justify="center" align="start">
 
         
         
     DORMITORIO
        
      <p class="text-left" >
   
      </p>
   
          
  </v-row>

     
          
            </v-expansion-panel-header>
   
        <v-expansion-panel-content class="text-justify " >

 
                  <br />
    <br />
El comedor es otro de los ambientes de nuestro hogar que reúne frecuentemente a la familia. Por ese carácter cotidiano, se desea que sea siempre un lugar cómodo y acogedor. Las cortinas y persianas FLEXALUM son ideales para convertirlo en un espacio único.
 
Los productos recomendados para tu comedor privilegian la luminosidad y los colores claros, con texturas suaves y decorativas que potencien tu estilo de decoración, y generen un entorno propicio para compartir deliciosas comidas e interesantes conversaciones.
    <br />

       <br />
    <v-row justify="center" align="start">
 
         
         
     NUESTRAS RECOMENDACIONES
        <br />
    <br />

   
          
  </v-row>
    <br />
             <v-row   class="hidden-sm-and-down" >
      <v-col 
        v-for="n in fotos"
        :key="n"
        class="d-flex child-flex"
        cols="4"


        
      >
     
         <v-hover v-slot="{ hover }">
           
<v-card       align="center"
              justify="center"  dark class="mx-auto grey darken-2">   <v-btn
        
        color="BLACK"
        dark
      >
{{n.titulo}}
      </v-btn> 
       
       
       
       
         <v-img
        
          :src="n.foto"
          :lazy-src="n.foto"
          aspect-ratio="1"
          class="grey lighten-2"
        >

 
         <v-expand-transition>
          
          
          
          <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal  text-h6 white--text"
              style="height: 100%;"
            >
 <v-card 
      class="mx-auto grey darken-2 text-justify text-h6"
      max-width="344"
      dark
      
   
    >
           <br />

              {{n.texto}}
                <br /> <br />
                 {{n.texto2}}


                 
    </v-card>


            </div>
          </v-expand-transition>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
</v-card>
    </v-hover>


 
      </v-col>
    </v-row>

   <v-row   class="hidden-sm-and-up" >
      <v-col 
        v-for="n in fotos"
        :key="n"
        class="d-flex child-flex"
        cols="12"


        
      >
     
         <v-hover v-slot="{ hover }">
           
<v-card       align="center"
              justify="center"  dark class="mx-auto grey darken-2">   <v-btn
        
        color="BLACK"
        dark
      >
{{n.titulo}}
      </v-btn> 
       
       
       
       
         <v-img
        
          :src="n.foto"
          :lazy-src="n.foto"
          aspect-ratio="1"
          class="grey lighten-2"
        >

 
         <v-expand-transition>
          
          
          
          <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal  text-h6 white--text"
              style="height: 100%;"
            >
 <v-card 
      class="mx-auto grey darken-2 text-justify text-h6"
      max-width="344"
      dark
      
   
    >
           <br />

              {{n.texto}}
                <br /> <br />
                 {{n.texto2}}


                 
    </v-card>


            </div>
          </v-expand-transition>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
</v-card>
    </v-hover>


 
      </v-col>
    </v-row>
  
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>





</template>

<script>
import db from "@/firebase/init.js";

export default {
  name: "Dormitorio",
  components: {},
  props: [],

  data() {
    return {

fotos: [{titulo:"Persiana de Madera",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/persiana-maderanat-04.jpg", 
texto:"Persinas de Madera: Diseño de láminas horizontales, combina la elegancia de la madera con un eficiente control de la luz para todo tipo de ambientes. Sus láminas horizontales de 50 mm están disponibles en dos colecciones con una amplia gama de colores veteados y lisos. El color de la base y cenefa es coordinado con las láminas",
texto2:"Su diseño moderno y elegante la convierte en una excelente opción para decorar estos espacios, ofreciendo un control variable de iluminación."},


{titulo:"Persiana de Aluminio",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/persiana-aluminio-08.jpg", 
texto:"Persiana de Aluminio: Producto también es conocido como persiana veneciana. Sus láminas están fabricadas en aluminio templado y son pintadas en horno de alta temperatura, lo que garantiza una alta resistencia a la deformación y decoloración.", 
texto2:"Por su reducido espacio y mínimo peso, son ideales para ser instaladas en planos inclinados, puertas vidriadas o ventanas abatibles (de techo)."},

{titulo:"Vertical",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/amb-comedor-05.jpg",
texto:"Tela Vertical: Su diseño se basa en un conjunto de láminas verticales de 89 mm de ancho montadas en un estilizado cabezal de aluminio. Utilizando una cadenilla plástica pueden girarse suavemente para ofrecer distintos niveles y ángulos de protección contra la luz solar. Además, mediante un accionamiento con cordón, la cortina se puede abrir completamente, replegando todas las láminas hacia un costado (izquierda o derecha) o desde el centro."},

{titulo:"Celular",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/amb-comedor-01.jpg",
texto:"Tela Celular: Su nombre se debe al hecho que está elaborada en tela de poliéster con una estructura de alta tecnología formada por pequeñas cámaras de aire o “células”.",
texto2:"Su principal atractivo, es que aporta una solución inteligente al problema de pérdida de calor. El espacio de aire en el interior de sus celdas permite reducir esa transferencia de energía desde y hacia el exterior, aportando a la disminución de costos de calefacción en invierno y aire acondicionado en verano. Como beneficio adicional, se produce un efectivo aislamiento acústico y protección contra los rayos UV.."},

{titulo:"Screen",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/amb-comedor-02.jpg",
texto:"Tela Screen: Esta tela ofrece tres tipos de abertura (3%, 5% y 10%), que permiten un paso controlado de la luz solar, haciendo posible ver claramente hacia el exterior.", texto2:"Uso recomendado para ambientes que necesiten buena visibilidad desde el interior hacia el exterior, pero que no requieran de un alto grado de privacidad. Debido a esto, no son recomendables para dormitorios, a menos que se usen en combinación con otras cortinas o persianas."},

{titulo:"Duo",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-duo-02.jpg",texto:"Telas Duo: Su doble tela en forma de franjas translúcidas y opacas incorpora una nueva funcionalidad, que permite obtener distintos niveles de opacidad y privacidad a medida que la cortina sube y baja",texto2:"Por su atractivo y novedoso diseño, así como por su alto nivel de transparencia, la cortina Duo se adapta muy bien a la decoración del Living, otorgando color y estilo."},

],


      ambientes:[{a:["https://picsum.photos/500/300?image=1","https://picsum.photos/500/300?image=2","https://picsum.photos/500/300?image=3"],nombre:"LIVING",texto:"El living es el espacio social por excelencia. Por una parte es el mejor lugar para reunir a la familia, amigos e invitados, pero también es el punto de entrada a nuestra casa. Por eso, la decoración puede decir mucho sobre nuestro estilo y carácter, y las cortinas son un elemento fundamental para lograrlo. Las diversas opciones de cortinas y persianas de FLEXALUM, gracias a sus materiales y componentes de alta tecnología, ofrecen diversos grados de control de la iluminación y aislación acústica y térmica. Esto permite convertir nuestro living en un ambiente acogedor y funcional durante todo el año, con un espectacular toque de diseño moderno."},
{nombre:"SAP Analytics Cloud ",texto:"BI -	Predictive -	Planning "},
{nombre:"SAP HANA",texto:"Hana Views - Hana Live - CDS Views"},
{nombre:"SAP BO",texto:"Web intelligence - Crystal report -	Lumira - BO Dashboard - Design Studio"}],

      productos: "",
      Titulo: " ",
      Descripcion: "",
      Linea: "",
      Lista: "",
   
    
    };
  },
  created() {
    var docRef = db.collection("web").doc("ANA");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().productos;
          this.Titulo = doc.data().Titulo;
          this.Descripción = doc.data().Descripción;
          this.Lista = doc.data().Lista;
          this.Linea = doc.data().Linea;
       

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
 

  },
};
</script>
