<template>
  <div id="app">
    <v-app id="inspire">
      <v-layout row justify-space-around wrap align-center>
        <v-card max-width="574">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="name" label="Name" required></v-text-field>

            <v-text-field
              v-model="email"
              label="E-mail"
              required
            ></v-text-field>

            <v-text-field
              v-model="Phone"
              label="Telefono"
              required
            ></v-text-field>

            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="regitro"
            >
              Registro
            </v-btn>
          </v-form>
        </v-card>
      </v-layout>
    </v-app>
  </div>
</template>

<script>
import db from "@/firebase/init.js";
import firebase from "firebase";
export default {
  name: "Ecommerce",
  components: {},
  props: [],

  data() {
    return {
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      phone: "",
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
    };
  },
  created() {},
  methods: {
    regitro() {
      var actionCodeSettings = {
  
  url: 'https://www.example.com/finishSignUp?cartId=1234',
  // This must be true.
  handleCodeInApp: true,

};
      console.log(this.email);
    },
  },

  computed: {},
};
</script>
