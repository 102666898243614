<template>
  


   
   
   <v-expansion-panels   accordion>
      <v-expansion-panel  
 
      >
        <v-expansion-panel-header color="grey "  expand-icon="mdi-check" disable-icon-rotate inset >       
          <v-row justify="center" align="start">
 
         
         
          LIVING
        
      <p class="text-left" >
   
      </p>
   
          
  </v-row>

     
          
            </v-expansion-panel-header>
   
        <v-expansion-panel-content class="text-justify " >

         <br />
    <br />
          
El living es el espacio social por excelencia. Por una parte es el mejor lugar para reunir a la familia, amigos e invitados, pero también es el punto de entrada a nuestra casa. Por eso, la decoración puede decir mucho sobre nuestro estilo y carácter, y las cortinas son un elemento fundamental para lograrlo. Las diversas opciones de cortinas y persianas de VALORA DESIGN, gracias a sus materiales y componentes de alta tecnología, ofrecen diversos grados de control de la iluminación y aislación acústica y térmica. Esto permite convertir nuestro living en un ambiente acogedor y funcional durante todo el año, con un espectacular toque de diseño moderno.
<br />
    <br />
    <v-row justify="center" align="start">
 
         
         
     NUESTRAS RECOMENDACIONES
        <br />
    <br />

   
          
  </v-row>
    <br />
             <v-row   class="hidden-sm-and-down" >
      <v-col 
        v-for="n in fotos"
        :key="n"
        class="d-flex child-flex"
        cols="4"


        
      >
     
         <v-hover v-slot="{ hover }">
           
<v-card       align="center"
              justify="center"  dark class="mx-auto grey darken-2">   <v-btn
        
        color="BLACK"
        dark
      >
{{n.titulo}}
      </v-btn> 
       
       
       
       
         <v-img
        
          :src="n.foto"
          :lazy-src="n.foto"
          aspect-ratio="1"
          class="grey lighten-2"
        >

 
         <v-expand-transition>
          
          
          
          <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal  text-h6 white--text"
              style="height: 100%;"
            >
 <v-card 
      class="mx-auto grey darken-2 text-justify text-h6"
      max-width="344"
      dark
      
   
    >
           <br />

              {{n.texto}}
                <br /> <br />
                 {{n.texto2}}


                 
    </v-card>


            </div>
          </v-expand-transition>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
</v-card>
    </v-hover>


 
      </v-col>
    </v-row>

   <v-row   class="hidden-sm-and-up" >
      <v-col 
        v-for="n in fotos"
        :key="n"
        class="d-flex child-flex"
        cols="12"


        
      >
     
         <v-hover v-slot="{ hover }">
           
<v-card       align="center"
              justify="center"  dark class="mx-auto grey darken-2">   <v-btn
        
        color="BLACK"
        dark
      >
{{n.titulo}}
      </v-btn> 
       
       
       
       
         <v-img
        
          :src="n.foto"
          :lazy-src="n.foto"
          aspect-ratio="1"
          class="grey lighten-2"
        >

 
         <v-expand-transition>
          
          
          
          <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal  text-h6 white--text"
              style="height: 100%;"
            >
 <v-card 
      class="mx-auto grey darken-2 text-justify text-h6"
      max-width="344"
      dark
      
   
    >
           <br />

              {{n.texto}}
                <br /> <br />
                 {{n.texto2}}


                 
    </v-card>


            </div>
          </v-expand-transition>
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
</v-card>
    </v-hover>


 
      </v-col>
    </v-row>
  
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>





</template>

<script>
import db from "@/firebase/init.js";

export default {
  name: "Living",
  components: {},
  props: [],

  data() {
    return {

fotos: [{titulo:"Screen",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-screen-01.jpg", texto:"Tela Screen: Esta tela ofrece tres tipos de abertura (3%, 5% y 10%), que permiten un paso controlado de la luz solar, haciendo posible ver claramente hacia el exterior.", texto2:"Uso recomendado para ambientes que necesiten buena visibilidad desde el interior hacia el exterior, pero que no requieran de un alto grado de privacidad. Debido a esto, no son recomendables para dormitorios, a menos que se usen en combinación con otras cortinas o persianas."},
{titulo:"Translúcida",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-translucida-02.jpg", texto:"Tela Translúcida: Cuentan con un grado opacidad mayor que las telas Screen, lo que ofrece un control más efectivo del paso y difusión de la luz solar.", texto2:"Esta tela es adecuada para el Living, pues entrega una suave y agradable iluminación natural con un buen grado de privacidad."},
{titulo:"Decorativa",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-decorativa-03.jpg", texto:"Tela Decorativa: Compuesta por un conjunto de colecciones con interesantes texturas, diseños y colores. Están fabricadas con combinaciones de poliéster, lino, algodón, vinilo, fibra de vidrio e, incluso, papel.", texto2:"Estas telas son muy recomendadas para aportar un estilo diferente al Living, incluyendo accesos, escaleras y pasillos, junto con una cálida iluminación natural y buena visibilidad hacia el exterior."},
{titulo:"Duo",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/roller-duo-05.jpg",texto:"Telas Duo: Su doble tela en forma de franjas translúcidas y opacas incorpora una nueva funcionalidad, que permite obtener distintos niveles de opacidad y privacidad a medida que la cortina sube y baja",texto2:"Por su atractivo y novedoso diseño, así como por su alto nivel de transparencia, la cortina Duo se adapta muy bien a la decoración del Living, otorgando color y estilo."},
{titulo:"Luxury",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/cortina-nantucket-06.jpg",texto:"Tela Luxury: Diseño espectacular que combina la belleza de las telas tradicionales con la funcionalidad de una persiana. Está compuesta por suaves láminas horizontales de tela suspendidas entre dos velos translúcidos",texto2:"Por su atractivo, elegancia y estilizado diseño, así como por su alto nivel de transparencia, la cortina Luxury es una opción muy recomendada para  Living, sobretodo cuando se desea privilegiar la visión hacia el exterior."},
{titulo:"Vertical",foto:"https://www.narvik.cl/wp-content/uploads/2015/08/cortina-vertical-03.jpg",texto:"Tela Vertical: Su diseño se basa en un conjunto de láminas verticales de 89 mm de ancho montadas en un estilizado cabezal de aluminio. Utilizando una cadenilla plástica pueden girarse suavemente para ofrecer distintos niveles y ángulos de protección contra la luz solar. Además, mediante un accionamiento con cordón, la cortina se puede abrir completamente, replegando todas las láminas hacia un costado (izquierda o derecha) o desde el centro."}],


      ambientes:[{a:["https://picsum.photos/500/300?image=1","https://picsum.photos/500/300?image=2","https://picsum.photos/500/300?image=3"],nombre:"LIVING",texto:"El living es el espacio social por excelencia. Por una parte es el mejor lugar para reunir a la familia, amigos e invitados, pero también es el punto de entrada a nuestra casa. Por eso, la decoración puede decir mucho sobre nuestro estilo y carácter, y las cortinas son un elemento fundamental para lograrlo. Las diversas opciones de cortinas y persianas de FLEXALUM, gracias a sus materiales y componentes de alta tecnología, ofrecen diversos grados de control de la iluminación y aislación acústica y térmica. Esto permite convertir nuestro living en un ambiente acogedor y funcional durante todo el año, con un espectacular toque de diseño moderno."},
{nombre:"SAP Analytics Cloud ",texto:"BI -	Predictive -	Planning "},
{nombre:"SAP HANA",texto:"Hana Views - Hana Live - CDS Views"},
{nombre:"SAP BO",texto:"Web intelligence - Crystal report -	Lumira - BO Dashboard - Design Studio"}],

      productos: "",
      Titulo: " ",
      Descripcion: "",
      Linea: "",
      Lista: "",
   
    
    };
  },
  created() {
    var docRef = db.collection("web").doc("ANA");
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.productos = doc.data().productos;
          this.Titulo = doc.data().Titulo;
          this.Descripción = doc.data().Descripción;
          this.Lista = doc.data().Lista;
          this.Linea = doc.data().Linea;
       

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    methods: {
      web() {
        this.$router.push({ path: "web" });
        this.dialog = false;
      },

      ecommerce() {
        this.$router.push({ path: "/ecommerce" });
        this.dialog = false;
      },

      market() {
        this.$router.push({ path: "/market" });
        this.dialog = false;
      },
      apps() {
        this.$router.push({ path: "/apps" });
        this.dialog = false;
      },
      foto() {
        this.$router.push({ path: "/foto" });
        this.dialog = false;
      },
      gestion() {
        this.$router.push({ path: "/social" });
        this.dialog = false;
      },
      inicio() {
        this.$router.push({ path: "/" });
        this.dialog = false;
      },
    },
  },

  computed: {
 

  },
};
</script>
